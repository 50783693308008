/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */






document.onreadystatechange = function() {
    if (document.readyState === 'complete')
        console.log('fonts-ready')
};




(function($) {






    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages


                // add custom media query sizes for Foundation Interchange
                Foundation.Interchange.SPECIAL_QUERIES.smallretina = 'only screen and (min-width: 1px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1px) and (min-resolution: 192dpi), only screen and (min-width: 1px) and (min-resolution: 2dppx)';
                Foundation.Interchange.SPECIAL_QUERIES.mediumretina = 'only screen and (min-width: 641px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 641px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 641px) and (min-device-pixel-ratio: 2), only screen and (min-width: 641px) and (min-resolution: 192dpi), only screen and (min-width: 641px) and (min-resolution: 2dppx)';
                Foundation.Interchange.SPECIAL_QUERIES.largeretina = 'only screen and (min-width: 1025px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1025px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1025px) and (min-resolution: 192dpi), only screen and (min-width: 1025px) and (min-resolution: 2dppx)';
                Foundation.Interchange.SPECIAL_QUERIES.xlargeretina = 'only screen and (min-width: 1921px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min--moz-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (-o-min-device-pixel-ratio: 2/1), only screen and (min-width: 1921px) and (min-device-pixel-ratio: 2), only screen and (min-width: 1921px) and (min-resolution: 192dpi), only screen and (min-width: 1921px) and (min-resolution: 2dppx)';

                // init Foundation JavaScript
                $(document).foundation();

                // add plz pattern for Foundation Abide
                Foundation.Abide.defaults.patterns.plz = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

                // set default Sticky size for Foundation Sticky
                Foundation.Sticky.defaults.stickyOn = 'large';

                // Mobile Menu
                Foundation.DropdownMenu.defaults.closeOnClick = true;



                var $btns = $('.btn').click(function() {
                    if (this.id == 'all') {
                        $('.featured-news-grid').fadeIn(450);
                        $('.normal-news-grid > div').fadeIn(450);
                    } else {
                        var $el = $('.normal-news-grid .' + this.id).fadeIn(450);
                        $('.normal-news-grid > div').not($el).hide();
                        $('.featured-news-grid').hide();
                    }
                    $('.btn').removeClass('active');
                    $(this).addClass('active');
                });




                $("#menu-mobile-menu .menu-item-has-children").click(function(){
                    $(this).toggleClass('show-sub');
                });


                if ( $("body").hasClass("single-projects")) {

                    var macy = Macy({
                        container: '.project__masonry',
                        trueOrder: true,
                        waitForImages: true,
                        margin: 24,
                        columns: 4,
                        breakAt: {
                            1200: 4,
                            940: 4,
                            520: 4,
                            400: 1
                        }
                    });


                    const videos = document.querySelectorAll('video'); // Get all video elements

                    let numReady = 0; // Counter for number of videos that are ready
                    let numPlaying = 0; // Counter for number of videos that are playing

                    videos.forEach(video => {
                        video.addEventListener('canplay', () => {
                            window.dispatchEvent(new Event('resize'));
                            numReady++; // Increment counter when video is ready
                            checkIfAllReadyAndPlaying(); // Check if all videos are ready and playing
                        });

                        video.addEventListener('play', () => {
                            window.dispatchEvent(new Event('resize'));
                            numPlaying++; // Increment counter when video is playing
                            checkIfAllReadyAndPlaying(); // Check if all videos are ready and playing
                        });
                    });

                    function checkIfAllReadyAndPlaying() {
                        if (numReady === videos.length && numPlaying === videos.length) {
                            window.dispatchEvent(new Event('resize'));
                        }
                    }


                    $("video").each(function (index) {
                        console.log($(this).find("source").data('src'));
                        $(this).find("source").attr("src", $(this).find("source").data('src'));
                        $(this).load();
                        console.log("reszied!");

                    });


                    setTimeout(function () {
                        window.dispatchEvent(new Event('resize'));
                    }, 1000); // prints "zero,one,two" after 2 seconds

                };


                $(".open-application").click(function(){
                    $(".job_studio__overlay").removeClass("inactive");
                });

                $(".report_studio__overlay__headline svg").click(function(){
                    $(".job_studio__overlay").addClass("inactive");
                });

                $('.menu-tiktokstudiotemplate a').attr('data-barba-prevent', 'self');

                function tiktok() {


                    if ( $("body").hasClass("single-casestudy") ) {



                        var executed = false;
                        $(window).scroll(function () {

                            if ($(".case_study__stats")[0]){
                                var top_of_element = $(".case_study__stats").offset().top;
                                var bottom_of_element = $(".case_study__stats").offset().top + $(".case_study__stats").outerHeight();

                            } else {
                            }

                            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                            var top_of_screen = $(window).scrollTop();

                            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                                if (!executed) {
                                    executed = true;
                                    $('.counter').each(function () {
                                        var $this = $(this),
                                            countTo = $this.attr('data-count');
                                        $({countNum: $this.text()}).animate({
                                                countNum: countTo
                                            },
                                            {
                                                duration: 1200,
                                                easing: 'linear',
                                                step: function () {
                                                    $this.text(Math.floor(this.countNum));
                                                },
                                                complete: function () {
                                                    $this.text(this.countNum);
                                                }
                                            });
                                    });
                                }

                            } else {
                            }
                        });
                    }





                    var executed = false;

                    if(document.querySelector('.report_studio__overlay') != null) {
                        if (!Cookies.get('trend-popup')) {
                            setTimeout(function() {
                                //  document.body.classList.add("locked");
                                document.querySelector('.report_studio__overlay').classList.remove('inactive');
                                $(".report_studio__overlay__headline svg").click(function(){
                                    Cookies.set('trend-popup', true, {expires: 7});
                                    document.querySelector('.report_studio__overlay').classList.add('inactive');
                                    // document.body.classList.remove("locked");
                                });


                            },10000);
                        }
                    }

                    if(document.querySelector('.report__overlay') != null) {
                        if (!Cookies.get('tiktok-popup')) {
                            setTimeout(function() {
                                //  document.body.classList.add("locked");
                                document.querySelector('.report__overlay').classList.remove('inactive');
                                $(".report_studio__overlay__headline svg").click(function(){
                                    Cookies.set('tiktok-popup', true, {expires: 7});
                                    document.querySelector('.report__overlay').classList.add('inactive');
                                    // document.body.classList.remove("locked");
                                });


                            },3000);
                        }
                    }


                    document.addEventListener( 'wpcf7mailsent', function( event ) {
                        $(".studio__overlay__headline__default, .submit").addClass('inactive');
                        $(".studio__overlay__headline__success").removeClass('inactive');
                        $(".studio__overlay__content__success").removeClass('inactive');
                        $(".studio__overlay__content").addClass('inactive');

                        $(".report__overlay__headline__default, .submit").addClass('inactive');
                        $(".report__overlay__headline__success").removeClass('inactive');
                        $(".report__overlay__content__success").removeClass('inactive');
                        $(".report__overlay__content").addClass('inactive');

                        $(".report_studio__overlay__headline__default, .submit").addClass('inactive');
                        $(".report_studio__overlay__headline__success").removeClass('inactive');
                        $(".report_studio__overlay__content__success").removeClass('inactive');
                        $(".report_studio__overlay__content").addClass('inactive');
                    }, false );



                    $(".report_studio__overlay__headline svg, .studio__overlay__headline svg, .report__overlay__headline svg, .close-svg-overlay").click(function(){
                        $(".studio__overlay__headline__default, .submit").removeClass('inactive');
                        $(".studio__overlay__headline__success").addClass('inactive');
                        $(".studio__overlay__content__success").addClass('inactive');
                        $(".studio__overlay__content").removeClass('inactive');

                        $(".report__overlay__headline__default, .submit").removeClass('inactive');
                        $(".report__overlay__headline__success").addClass('inactive');
                        $(".report__overlay__content__success").addClass('inactive');
                        $(".report__overlay__content").removeClass('inactive');

                        $(".report_studio__overlay__headline__default, .submit").removeClass('inactive');
                        $(".report_studio__overlay__headline__success").addClass('inactive');
                        $(".report_studio__overlay__content__success").addClass('inactive');
                        $(".report_studio__overlay__content").removeClass('inactive');
                    });



                    $(".studio__hero__push-to-start, .studio__outro__button, .studio__prices__item__button, .studio__overlay__headline svg, .studio__scene__text__3 .underline, .studio__scene__texts__mobile__button, .case_study__outro__button, .case_study__button__inner").click(function(){
                        $(".studio__overlay").toggleClass('inactive');
                    });

                    $(".report__download__one__button, .report__download__two__button, .report__overlay__headline svg, .trend-image").click(function(){
                        $(".report__overlay").toggleClass('inactive');
                    });

                    $(".lux__trigger").click(function(){
                        $(".lux__overlay").toggleClass('inactive');
                    });

                    $(".chaos__trigger").click(function(){
                        $(".chaos__overlay").toggleClass('inactive');
                    });













                    $(".custom-select").each(function() {
                        var classes = $(this).attr("class"),
                            id      = $(this).attr("id"),
                            name    = $(this).attr("name");
                        var template =  '<div class="' + classes + '">';
                        template += '<span class="custom-select-trigger">' + "Interested in" + '</span>';
                        template += '<div class="custom-options">';
                        $(this).find("option").each(function() {
                            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
                        });
                        template += '</div></div>';

                        $(this).wrap('<div class="custom-select-wrapper custom-select-default"></div>');
                        $(this).hide();
                        $(this).after(template);
                    });

                    $(".field-select").each(function() {
                        var classes = $(this).attr("class"),
                            id      = $(this).attr("id"),
                            name    = $(this).attr("name");
                        var template =  '<div class="' + classes + '">';
                        template += '<span class="custom-select-trigger">' + "Discipline" + '</span>';
                        template += '<div class="custom-options">';
                        $(this).find("option").each(function() {
                            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
                        });
                        template += '</div></div>';

                        $(this).wrap('<div class="custom-select-wrapper custom-select-default"></div>');
                        $(this).hide();
                        $(this).after(template);
                    });

                    $(".experience-select").each(function() {
                        var classes = $(this).attr("class"),
                            id      = $(this).attr("id"),
                            name    = $(this).attr("name");
                        var template =  '<div class="' + classes + '">';
                        template += '<span class="custom-select-trigger">' + "Experience level" + '</span>';
                        template += '<div class="custom-options">';
                        $(this).find("option").each(function() {
                            template += '<span class="custom-option ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
                        });
                        template += '</div></div>';

                        $(this).wrap('<div class="custom-select-wrapper custom-select-default"></div>');
                        $(this).hide();
                        $(this).after(template);
                    });

                    $(".hear-select").each(function() {
                        var classes = $(this).attr("class"),
                            id      = $(this).attr("id"),
                            name    = $(this).attr("name");
                        var template =  '<div class="' + classes + '">';
                        template += '<span class="custom-select-trigger-2">' + "Where did you hear about us" + '</span>';
                        template += '<div class="custom-options-2">';
                        $(this).find("option").each(function() {
                            template += '<span class="custom-option-2 ' + $(this).attr("class") + '" data-value="' + $(this).attr("value") + '">' + $(this).html() + '</span>';
                        });
                        template += '</div></div>';

                        $(this).wrap('<div class="custom-select-wrapper custom-select-default"></div>');
                        $(this).hide();
                        $(this).after(template);
                    });





                    $(".custom-option:first-of-type").hover(function() {
                        $(this).parents(".custom-options").addClass("option-hover");
                    }, function() {
                        $(this).parents(".custom-options").removeClass("option-hover");
                    });
                    $(".custom-select-trigger").on("click", function() {
                        $('html').one('click',function() {
                            $(".custom-select-wrapper").addClass("custom-select-non-default");
                            $(".custom-select, .field-select, .experience-select, .hear-select").removeClass("opened");
                        });
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").toggleClass("opened");
                        event.stopPropagation();
                    });

                    $(".custom-option-2:first-of-type").hover(function() {
                        $(this).parents(".custom-options-2").addClass("option-hover");
                    }, function() {
                        $(this).parents(".custom-options-2").removeClass("option-hover");
                    });

                    $(".custom-select-trigger-2").on("click", function() {
                        $('html').one('click',function() {
                            $(".custom-select-wrapper").addClass("custom-select-non-default");
                            $(".custom-select, .field-select, .experience-select, .hear-select").removeClass("opened");
                        });
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").toggleClass("opened");
                        event.stopPropagation();
                    });






                    $(".custom-option").on("click", function() {
                        $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
                        $(this).parents(".custom-options").find(".custom-option").removeClass("selection");
                        $(this).addClass("selection");
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").removeClass("opened");
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").find(".custom-select-trigger").text($(this).text());
                        if ($(this).data("value") === 'Other (please specify)') {
                            $('.field-other').addClass("active")
                        }
                        else {
                            $('.field-other').removeClass("active")
                        }

                    });


                    $(".custom-option-2").on("click", function() {
                        $(this).parents(".custom-select-wrapper").find("select").val($(this).data("value"));
                        $(this).parents(".custom-options-2").find(".custom-option-2").removeClass("selection");
                        $(this).addClass("selection");
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").removeClass("opened");
                        $(this).parents(".custom-select, .field-select, .experience-select, .hear-select").find(".custom-select-trigger-2").text($(this).text());
                        if ($(this).data("value") === 'Other (please specify)') {
                            $('.hear-other').addClass("active")
                        }
                        else {
                            $('.hear-other').removeClass("active")
                        }
                    });







                    /*
                    var canvas = document.getElementById('canvas'),
                        ctx = canvas.getContext('2d');

                    function resize() {
                        canvas.width = window.innerWidth;
                        canvas.height = window.innerHeight;
                    }
                    resize();
                    window.onresize = resize;

                    function noise(ctx) {

                        var w = ctx.canvas.width,
                            h = ctx.canvas.height,
                            idata = ctx.createImageData(w, h),
                            buffer32 = new Uint32Array(idata.data.buffer),
                            len = buffer32.length,
                            i = 0;

                        for(; i < len;)
                            buffer32[i++] = ((255 * Math.random())|0) << 24;

                        ctx.putImageData(idata, 0, 0);
                    }

                    var toggle = true;

    // added toggle to get 30 FPS instead of 60 FPS
                    (function loop() {
                        toggle = !toggle;
                        if (toggle) {
                            requestAnimationFrame(loop);
                            return;
                        }
                        noise(ctx);
                        requestAnimationFrame(loop);
                    })();


    */
                    if ( $("body").hasClass("single-casestudy") || $("body").hasClass("page-template-template-tiktok_studio") || $("body").hasClass("page-template-template-tiktok_studio_new") || $("body").hasClass("page-template-template-tiktok_studio_google_ads")  ) {


                        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                            window.addEventListener("orientationchange", function() {
                                location.reload();
                            }, false);
                        }
                        else {
                            window.onresize = function(){
                                location.reload();
                            }
                        }




                    }

                    if ( $("body").hasClass("single-casestudy") || $("body").hasClass("page-template-template-tiktok_studio") ) {

                        if ($(window).width() > 768) {
                            var tl_hero_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero_case_study",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_hero_case_study.to(".case_study__categories", {left: "-200vw", duration: 30}, 0);
                        }

                        if ($(window).width() > 768) {
                            var tl_stats_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".case_study__stats",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_stats_case_study.from(".case_study__stats__number", {scale: "0", duration: 30}, 0);
                        }




                        gsap.registerPlugin(ScrollTrigger);

                        if ($(window).width() > 768) {
                            var tl_intro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__intro",
                                    start: "2%",
                                    end: "110%",
                                    scrub: true,
                                    //pin: true,
                                    //    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__header__logo").addClass('active'),
                                    onLeaveBack: () => $(".studio__header__logo").removeClass('active'),
                                }

                            });

                            tl_intro
                                .from(".studio__intro__headline", {opacity: 0}, 0)
                                .from(".studio__intro__stroke__top", {height: 0}, 1)
                                .from(".studio__intro__logos", {opacity: 0}, 4)
                                //  .from(".studio__intro__wheel__group", {opacity: 0}, 5)
                                .from(".studio__intro__stroke__bottom", {height: 0}, 6)
                                .from(".studio__intro__subline", {opacity: 0}, 8)

                        }




                        if ($("body").hasClass("single-casestudy") && $(window).width() > 768 || $("body").hasClass("page-template-template-tiktok_studio")  ) {
                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                    //     markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                            if ($(window).height() > 600 && $(window).width() > 1024) {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "70%" },0)
                                    .to(".studio__hero__img__1", {top: "-70%", duration: 2}, 0)
                                    .to(".studio__hero__img__2", {top: "-50%", duration: 2.8}, 0)
                                    .to(".studio__hero__img__6", {top: "-50%", duration: 2.6}, 0.2)
                                    .to(".studio__hero__img__3", {top: "-60%", duration: 2.1}, 0)
                                    .to(".studio__hero__img__4", {top: "-40%", duration: 1.5}, 0.2)
                                    .to(".studio__hero__img__5", {top: "-100%", duration: 2}, 0)
                                    .to(".studio__hero__img__7", {top: "-60%", duration: 2.6}, 0.3)
                            }
                            else {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "60%" },0)
                            }
                        }

                        if ( $("body").hasClass("page-template-template-tiktok_studio_new") ) {
                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "15%",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                    markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                            if ($(window).height() > 600 && $(window).width() > 1024) {
                                tl_hero
                                    .to(".studio__hero__img__1", {top: "-70%", duration: 1}, 0)
                                    .to(".studio__hero__img__2", {top: "-50%", duration: 1.8}, 0)
                                    .to(".studio__hero__img__6", {top: "-50%", duration: 1.6}, 0.1)
                                    .to(".studio__hero__img__3", {top: "-60%", duration: 1.1}, 0)
                                    .to(".studio__hero__img__4", {top: "-40%", duration: 0.5}, 0.1)
                                    .to(".studio__hero__img__5", {top: "-100%", duration: 1}, 0)
                                    .to(".studio__hero__img__7", {top: "-60%", duration: 1.6}, 0.1)
                            }
                            else {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "60%" },0)
                            }
                        }



                        //  .to(".studio__hero__push-to-start",  { opacity: 1 });


                        if ($(window).width() > 768) {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });

                            tl_outro.to(".studio__outro svg", {scale: 2}, 0);
                        }
                        else {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });
                        }





                        if ($(window).width() > 768) {
                            var tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__scene__wrapper",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: 3,
                                    pin: true,
                                    onEnterBack: () => $(".studio__hero__push-to-start").addClass('active'),
                                    onLeave: () => $(".studio__hero__push-to-start").removeClass('active'),
                                    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                }


                            })
                            /*  tl.from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                              .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                              .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                              .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                  .to(".studio__scene__asset__1",  {  y:-500 },1)
                                  .to(".studio__scene__asset__2",  {  y:-800 },1)
                                  .to(".studio__scene__asset__3",  {  y:-700 },1)
                                  .to(".studio__scene__asset__4",  {  y:-500 },1)*/

                            tl.from(".phone-img", {scale: 0.5}, 0)
                                // .to(".studio__hero__push-to-start",  { opacity: 1 },0)
                                /*     .from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                                     .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                                     .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__5",  { opacity: 0, y:100 },0)
                                 .from(".studio__scene__asset__6",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__7",  { opacity: 0, y:300 },0)
                                 .from(".studio__scene__asset__8",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__9",  { opacity: 0, y:200 },0)*/
                                .to(".studio__scene__asset__1", {top: "-40%", duration: 3}, 0)
                                .to(".studio__scene__asset__2", {top: "-50%", duration: 2.6}, 0.2)
                                .to(".studio__scene__asset__3", {top: "-60%", duration: 2.8}, 0)
                                .to(".studio__scene__asset__4", {top: "-40%", duration: 2.2}, 0.4)
                                .to(".studio__scene__asset__5", {top: "-20%", duration: 2.4}, 0.5)
                                .to(".studio__scene__asset__6", {top: "-30%", duration: 2.9}, 0.2)
                                .to(".studio__scene__asset__7", {top: "-60%", duration: 2.7}, 0.6)
                                .to(".studio__scene__asset__8", {top: "-100%", duration: 2}, 0.3)
                                .to(".studio__scene__asset__9", {top: "-40%", duration: 2.5}, 0.6)
                                // .to(".asset", {top: "-20%", duration: 1},0)
                                .to(".studio__scene__phone", {x: -150, duration: 2.5}, 3)
                                .to(".studio__scene__text__1", {opacity: 1}, 5.9)
                                .to(".studio__scene__text__1", {opacity: 1}, 10)
                                .to(".studio__scene__text__2", {opacity: 1}, 14)
                                .to(".studio__scene__text__2", {opacity: 1}, 18)
                                .to(".studio__scene__text__3", {opacity: 1}, 22)
                                .to(".studio__scene__text__3", {opacity: 1}, 24)
                                .to(".studio__scene__text__3", {opacity: 1}, 32)
                            //  .to(".studio__hero__push-to-start",  { opacity: 1 },3.5)
                            // .to(".studio__hero__push-to-start",  { opacity: 0 },4);
                        }

                        var tl_forward= gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__projects__headline",
                                start: "-100%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_forward
                            .from(".studio__projects__underline",  { width: 0  },0);


                        var tl_slogan = gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__slogan",
                                start: "-120%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_slogan
                            .from(".studio__slogan__stroke",  { height: 0  },0)
                            .from(".underline__inner",  { width: 0  },0.2);





                        function getSliderSettings_Studio() {
                            return {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: "33%",
                                dots: true,
                                autoplay: true,
                                arrows: true,
                                prevArrow: $('.arrow-prev'),
                                nextArrow: $('.arrow-next'),
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            arrows: true,
                                            dots: true,
                                            slidesToShow: 1,
                                            centerPadding: "20%",
                                        }
                                    }
                                ]
                            }

                        }

                        $('.slider-studio').slick(getSliderSettings_Studio());

                    }
                    else if ($("body").hasClass("page-template-template-tiktok_studio_google_ads")) {

                        if ($(window).width() > 768) {
                            var tl_hero_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero_case_study",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_hero_case_study.to(".case_study__categories", {left: "-200vw", duration: 30}, 0);
                        }

                        if ($(window).width() > 768) {
                            var tl_stats_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".case_study__stats",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_stats_case_study.from(".case_study__stats__number", {scale: "0", duration: 30}, 0);
                        }




                        gsap.registerPlugin(ScrollTrigger);

                        if ($(window).width() > 768) {
                            var tl_intro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__intro",
                                    start: "2%",
                                    end: "110%",
                                    scrub: true,
                                    //pin: true,
                                    //    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__header__logo").addClass('active'),
                                    onLeaveBack: () => $(".studio__header__logo").removeClass('active'),
                                }

                            });

                            tl_intro
                                .from(".studio__intro__headline", {opacity: 0}, 0)
                                .from(".studio__intro__stroke__top", {height: 0}, 1)
                                .from(".studio__intro__logos", {opacity: 0}, 4)
                                //  .from(".studio__intro__wheel__group", {opacity: 0}, 5)
                                .from(".studio__intro__stroke__bottom", {height: 0}, 6)
                                .from(".studio__intro__subline", {opacity: 0}, 8)

                        }




                        if ($("body").hasClass("single-casestudy") && $(window).width() > 768 || $("body").hasClass("page-template-template-tiktok_studio") || $("body").hasClass("page-template-template-tiktok_studio_google_ads") ) {
                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                    //     markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                        }



                        //  .to(".studio__hero__push-to-start",  { opacity: 1 });


                        if ($(window).width() > 768) {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });

                            tl_outro.to(".studio__outro svg", {scale: 2}, 0);
                        }
                        else {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });
                        }





                        if ($(window).width() > 1024) {
                            var tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__scene__wrapper",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: 3,
                                    pin: true,
                                    onEnterBack: () => $(".studio__hero__push-to-start").addClass('active'),
                                    onLeave: () => $(".studio__hero__push-to-start").removeClass('active'),
                                    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                }




                            })
                            /*  tl.from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                              .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                              .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                              .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                  .to(".studio__scene__asset__1",  {  y:-500 },1)
                                  .to(".studio__scene__asset__2",  {  y:-800 },1)
                                  .to(".studio__scene__asset__3",  {  y:-700 },1)
                                  .to(".studio__scene__asset__4",  {  y:-500 },1)*/

                            tl.from(".phone-img", {scale: 0.5}, 0)
                                // .to(".studio__hero__push-to-start",  { opacity: 1 },0)
                                /*     .from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                                     .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                                     .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__5",  { opacity: 0, y:100 },0)
                                 .from(".studio__scene__asset__6",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__7",  { opacity: 0, y:300 },0)
                                 .from(".studio__scene__asset__8",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__9",  { opacity: 0, y:200 },0)*/
                                .to(".studio__scene__asset__1", {top: "-40%", duration: 3}, 0)
                                .to(".studio__scene__asset__2", {top: "-50%", duration: 2.6}, 0.2)
                                .to(".studio__scene__asset__3", {top: "-60%", duration: 2.8}, 0)
                                .to(".studio__scene__asset__4", {top: "-40%", duration: 2.2}, 0.4)
                                .to(".studio__scene__asset__5", {top: "-20%", duration: 2.4}, 0.5)
                                .to(".studio__scene__asset__6", {top: "-30%", duration: 2.9}, 0.2)
                                .to(".studio__scene__asset__7", {top: "-60%", duration: 2.7}, 0.6)
                                .to(".studio__scene__asset__8", {top: "-100%", duration: 2}, 0.3)
                                .to(".studio__scene__asset__9", {top: "-40%", duration: 2.5}, 0.6)
                                // .to(".asset", {top: "-20%", duration: 1},0)
                                .to(".studio__scene__phone", {x: -150, duration: 2.5}, 3)
                                .to(".studio__scene__text__1", {opacity: 1}, 5.9)
                                .to(".studio__scene__text__1", {opacity: 1}, 10)
                                .to(".studio__scene__text__2", {opacity: 1}, 14)
                                .to(".studio__scene__text__2", {opacity: 1}, 18)
                                .to(".studio__scene__text__3", {opacity: 1}, 22)
                                .to(".studio__scene__text__3", {opacity: 1}, 24)
                                .to(".studio__scene__text__3", {opacity: 1}, 32)
                            //  .to(".studio__hero__push-to-start",  { opacity: 1 },3.5)
                            // .to(".studio__hero__push-to-start",  { opacity: 0 },4);
                        }

                        var tl_forward= gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__projects__headline",
                                start: "-100%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_forward
                            .from(".studio__projects__underline",  { width: 0  },0);


                        var tl_slogan = gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__slogan",
                                start: "-120%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_slogan
                            .from(".studio__slogan__stroke",  { height: 0  },0)
                            .from(".underline__inner",  { width: 0  },0.2);






                        function getSliderSettings_Studio() {
                            return {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: "33%",
                                dots: true,
                                arrows: true,
                                prevArrow: $('.arrow-prev'),
                                nextArrow: $('.arrow-next'),
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            arrows: true,
                                            dots: true,
                                            slidesToShow: 1,
                                            centerPadding: "20%",
                                        }
                                    }
                                ]
                            }

                        }

                        $('.slider-studio').slick(getSliderSettings_Studio());
                    }
                    else {

                    }

                    if ( $("body").hasClass("page-template-template-tiktok_studio_new") ) {

                        if ($(window).width() > 768) {
                            var tl_hero_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero_case_study",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_hero_case_study.to(".case_study__categories", {left: "-200vw", duration: 30}, 0);
                        }

                        if ($(window).width() > 768) {
                            var tl_stats_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".case_study__stats",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_stats_case_study.from(".case_study__stats__number", {scale: "0", duration: 30}, 0);
                        }




                        gsap.registerPlugin(ScrollTrigger);

                        if ($(window).width() > 1) {
                            var tl_intro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__intro",
                                    start: "-75%",
                                    end: "25%",
                                    scrub: true,
                                    //pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__header__logo").addClass('active'),
                                    onLeaveBack: () => $(".studio__header__logo").removeClass('active'),
                                }

                            });

                            tl_intro
                                .from(".studio__intro__headline", {opacity: 0 ,  duration: 1}, 0)
                                .from(".studio__intro__logos", {opacity: 0,  duration: 2}, 1)
                                .from(".studio__intro__stroke__bottom", {height: 0 ,  duration: 3}, 2)
                                .from(".studio__intro__subline", {opacity: 0 ,  duration: 4}, 3)

                        }




                        if ($("body").hasClass("single-casestudy") && $(window).width() > 768 || $("body").hasClass("page-template-template-tiktok_studio")  ) {
                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                    //     markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                            if ($(window).height() > 600 && $(window).width() > 1024) {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "70%" },0)
                                    .to(".studio__hero__img__1", {top: "-70%", duration: 2}, 0)
                                    .to(".studio__hero__img__2", {top: "-50%", duration: 2.8}, 0)
                                    .to(".studio__hero__img__6", {top: "-50%", duration: 2.6}, 0.2)
                                    .to(".studio__hero__img__3", {top: "-60%", duration: 2.1}, 0)
                                    .to(".studio__hero__img__4", {top: "-40%", duration: 1.5}, 0.2)
                                    .to(".studio__hero__img__5", {top: "-100%", duration: 2}, 0)
                                    .to(".studio__hero__img__7", {top: "-60%", duration: 2.6}, 0.3)
                            }
                            else {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "60%" },0)
                            }
                        }

                        if ( $("body").hasClass("page-template-template-tiktok_studio_new") ) {

                            $('a[href*=\\#]').on('click', function(event){
                                event.preventDefault();
                                $('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
                            });

                            $(".arrow__up").click(function() {
                                $("html, body").animate({ scrollTop: 0 }, "slow");
                                return false;
                            });

                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "15%",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                   //      markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                            if ($(window).height() > 600 && $(window).width() > 1024) {
                                tl_hero
                                    .to(".studio__hero__img__1", {top: "-70%", duration: 1}, 0)
                                    .to(".studio__hero__img__2", {top: "-50%", duration: 1.8}, 0)
                                    .to(".studio__hero__img__6", {top: "-50%", duration: 1.6}, 0.1)
                                    .to(".studio__hero__img__3", {top: "-60%", duration: 1.1}, 0)
                                    .to(".studio__hero__img__4", {top: "-40%", duration: 0.5}, 0.1)
                                    .to(".studio__hero__img__5", {top: "-100%", duration: 1}, 0)
                                    .to(".studio__hero__img__7", {top: "-60%", duration: 1.6}, 0.1)
                            }
                            else {
                                tl_hero.to(".studio__hero__tiktok-studio",  { bottom:"40%", scale: 1 },0)
                                    .to(".studio__hero__logo",  { bottom: "60%" },0)
                            }
                        }



                        //  .to(".studio__hero__push-to-start",  { opacity: 1 });


                        if ($(window).width() > 768) {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });

                            tl_outro.to(".studio__outro svg", {scale: 2}, 0);
                        }
                        else {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });
                        }





                        if ($(window).width() > 768) {
                            var tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__scene__wrapper",
                                    start: "-40%",
                                    end: "120%" ,
                                    scrub: true,
                                    pin: false,
                                    onEnterBack: () => $(".studio__hero__push-to-start").addClass('active'),
                                    onLeave: () => $(".studio__hero__push-to-start").addClass('active'),
                                    pinSpacing: false,
                                        markers: false,
                                    id: "scrub",
                                }



                            })
                            /*  tl.from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                              .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                              .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                              .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                  .to(".studio__scene__asset__1",  {  y:-500 },1)
                                  .to(".studio__scene__asset__2",  {  y:-800 },1)
                                  .to(".studio__scene__asset__3",  {  y:-700 },1)
                                  .to(".studio__scene__asset__4",  {  y:-500 },1)*/

                            tl.from(".phone-img", {scale: 0.5}, 0)
                                // .to(".studio__hero__push-to-start",  { opacity: 1 },0)
                                /*     .from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                                     .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                                     .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__5",  { opacity: 0, y:100 },0)
                                 .from(".studio__scene__asset__6",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__7",  { opacity: 0, y:300 },0)
                                 .from(".studio__scene__asset__8",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__9",  { opacity: 0, y:200 },0)*/
                                .to(".studio__scene__asset__1", {top: "-40%", duration: 3}, 0)
                                .to(".studio__scene__asset__2", {top: "-50%", duration: 2.6}, 0.2)
                                .to(".studio__scene__asset__3", {top: "-60%", duration: 2.8}, 0)
                                .to(".studio__scene__asset__4", {top: "-40%", duration: 2.2}, 0.4)
                                .to(".studio__scene__asset__5", {top: "-20%", duration: 2.4}, 0.5)
                                .to(".studio__scene__asset__6", {top: "-30%", duration: 2.9}, 0.2)
                                .to(".studio__scene__asset__7", {top: "-60%", duration: 2.7}, 0.6)
                                .to(".studio__scene__asset__8", {top: "-100%", duration: 2}, 0.3)
                                .to(".studio__scene__asset__9", {top: "-40%", duration: 2.5}, 0.6)
                                // .to(".asset", {top: "-20%", duration: 1},0)
                                .to(".studio__scene__phone", {x: -150, duration: 1}, 0)
                                .to(".studio__scene__text__1", {opacity: 1, duration: 1}, 0)
                                .to(".studio__scene__text__1", {opacity: 1 , duration: 1}, 1)
                                .to(".studio__scene__text__2", {opacity: 1 , duration: 1}, 0)
                                .to(".studio__scene__text__2", {opacity: 1 , duration: 1}, 1)
                                .to(".studio__scene__text__3", {opacity: 1 , duration: 1}, 0)
                                .to(".studio__scene__text__3", {opacity: 1 , duration: 1}, 1)
                             //  .to(".studio__scene__text__4", {opacity: 1 , duration: 1}, 1)
                             //   .to(".studio__scene__text__4", {opacity: 1 , duration: 1}, 1)
                             //   .to(".studio__scene__text__4", {opacity: 1 , duration: 1}, 1)
                             //   .to(".studio__scene__text__4", {opacity: 1 , duration: 1}, 1)
                            //  .to(".studio__hero__push-to-start",  { opacity: 1 },3.5)
                            // .to(".studio__hero__push-to-start",  { opacity: 0 },4);
                        }

                        var tl_forward= gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__projects__headline",
                                start: "-100%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_forward
                            .from(".studio__projects__underline",  { width: 0  },0);


                        var tl_slogan = gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__slogan",
                                start: "-120%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_slogan
                            .from(".studio__slogan__stroke",  { height: 0  },0)
                            .from(".underline__inner",  { width: 0  },0.2);



                        function getSliderSettings_Services() {
                            return {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                arrows: true,
                                prevArrow: $('.arrow-prev'),
                                nextArrow: $('.arrow-next'),
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            arrows: true
                                        }
                                    }
                                ]
                            }

                        }

                        $('.services-slider').slick(getSliderSettings_Services());


                        function getSliderSettings_Studio() {
                            return {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: "28%",
                                prevArrow: $('.arrow-prev'),
                                nextArrow: $('.arrow-next'),
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            centerPadding: "10%",
                                        }
                                    }
                                ]
                            }

                        }

                        $('.slider-studio').slick(getSliderSettings_Studio());

                    }
                    else if ($("body").hasClass("page-template-template-tiktok_studio_google_ads")) {

                        if ($(window).width() > 768) {
                            var tl_hero_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero_case_study",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_hero_case_study.to(".case_study__categories", {left: "-200vw", duration: 30}, 0);
                        }

                        if ($(window).width() > 768) {
                            var tl_stats_case_study = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".case_study__stats",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    // pin: true,
                                    //    pinSpacing: false,
                                    // markers: true,
                                    id: "scrub",
                                }

                            });

                            tl_stats_case_study.from(".case_study__stats__number", {scale: "0", duration: 30}, 0);
                        }




                        gsap.registerPlugin(ScrollTrigger);

                        if ($(window).width() > 768) {
                            var tl_intro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__intro",
                                    start: "2%",
                                    end: "110%",
                                    scrub: true,
                                    //pin: true,
                                    //    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__header__logo").addClass('active'),
                                    onLeaveBack: () => $(".studio__header__logo").removeClass('active'),
                                }

                            });

                            tl_intro
                                .from(".studio__intro__headline", {opacity: 0}, 0)
                                .from(".studio__intro__stroke__top", {height: 0}, 1)
                                .from(".studio__intro__logos", {opacity: 0}, 4)
                                //  .from(".studio__intro__wheel__group", {opacity: 0}, 5)
                                .from(".studio__intro__stroke__bottom", {height: 0}, 6)
                                .from(".studio__intro__subline", {opacity: 0}, 8)

                        }




                        if ($("body").hasClass("single-casestudy") && $(window).width() > 768 || $("body").hasClass("page-template-template-tiktok_studio") || $("body").hasClass("page-template-template-tiktok_studio_google_ads") ) {
                            var tl_hero = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__hero",
                                    start: "top",
                                    end: "bottom",
                                    scrub: true,
                                    pin: true,
                                    //    pinSpacing: false,
                                    //     markers: true,
                                    id: "scrub",
                                    onLeave: () => {
                                        $(".studio__hero__push-to-start").addClass('active');
                                        AOS.refresh();
                                    },
                                    onEnterBack: () => $(".studio__hero__push-to-start").removeClass('active'),
                                }

                            });
                        }



                        //  .to(".studio__hero__push-to-start",  { opacity: 1 });


                        if ($(window).width() > 768) {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });

                            tl_outro.to(".studio__outro svg", {scale: 2}, 0);
                        }
                        else {
                            var tl_outro = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".outro_trigger",
                                    start: "-50%",
                                    end: "center",
                                    scrub: true,
                                    pin: false,
                                    //    pinSpacing: false,
                                    //   markers: true,
                                    id: "scrub",
                                    onEnter: () => $(".studio__scroller, .studio__hero__push-to-start").addClass('inactive'),
                                    onLeaveBack: () => $(".studio__scroller, .studio__hero__push-to-start").removeClass('inactive'),
                                }

                            });
                        }





                        if ($(window).width() > 1024) {
                            var tl = gsap.timeline({
                                scrollTrigger: {
                                    trigger: ".studio__scene__wrapper",
                                    start: "top",
                                    end: "+=" + window.innerHeight * 3,
                                    scrub: 3,
                                    pin: true,
                                    onEnterBack: () => $(".studio__hero__push-to-start").addClass('active'),
                                    onLeave: () => $(".studio__hero__push-to-start").removeClass('active'),
                                    pinSpacing: false,
                                    //    markers: true,
                                    id: "scrub",
                                }




                            })
                            /*  tl.from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                              .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                              .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                              .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                  .to(".studio__scene__asset__1",  {  y:-500 },1)
                                  .to(".studio__scene__asset__2",  {  y:-800 },1)
                                  .to(".studio__scene__asset__3",  {  y:-700 },1)
                                  .to(".studio__scene__asset__4",  {  y:-500 },1)*/

                            tl.from(".phone-img", {scale: 0.5}, 0)
                                // .to(".studio__hero__push-to-start",  { opacity: 1 },0)
                                /*     .from(".studio__scene__asset__1",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__2",  { opacity: 0, y:200 },0)
                                     .from(".studio__scene__asset__3",  { opacity: 0, y:300 },0)
                                     .from(".studio__scene__asset__4",  { opacity: 0, y:500 },0)
                                     .from(".studio__scene__asset__5",  { opacity: 0, y:100 },0)
                                 .from(".studio__scene__asset__6",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__7",  { opacity: 0, y:300 },0)
                                 .from(".studio__scene__asset__8",  { opacity: 0, y:500 },0)
                                 .from(".studio__scene__asset__9",  { opacity: 0, y:200 },0)*/
                                .to(".studio__scene__asset__1", {top: "-40%", duration: 3}, 0)
                                .to(".studio__scene__asset__2", {top: "-50%", duration: 2.6}, 0.2)
                                .to(".studio__scene__asset__3", {top: "-60%", duration: 2.8}, 0)
                                .to(".studio__scene__asset__4", {top: "-40%", duration: 2.2}, 0.4)
                                .to(".studio__scene__asset__5", {top: "-20%", duration: 2.4}, 0.5)
                                .to(".studio__scene__asset__6", {top: "-30%", duration: 2.9}, 0.2)
                                .to(".studio__scene__asset__7", {top: "-60%", duration: 2.7}, 0.6)
                                .to(".studio__scene__asset__8", {top: "-100%", duration: 2}, 0.3)
                                .to(".studio__scene__asset__9", {top: "-40%", duration: 2.5}, 0.6)
                                // .to(".asset", {top: "-20%", duration: 1},0)
                                .to(".studio__scene__phone", {x: -150, duration: 2.5}, 3)
                                .to(".studio__scene__text__1", {opacity: 1}, 5.9)
                                .to(".studio__scene__text__1", {opacity: 1}, 10)
                                .to(".studio__scene__text__2", {opacity: 1}, 14)
                                .to(".studio__scene__text__2", {opacity: 1}, 18)
                                .to(".studio__scene__text__3", {opacity: 1}, 22)
                                .to(".studio__scene__text__3", {opacity: 1}, 24)
                                .to(".studio__scene__text__3", {opacity: 1}, 32)
                                .to(".studio__scene__text__4", {opacity: 1}, 36)
                                .to(".studio__scene__text__4", {opacity: 1}, 40)
                                .to(".studio__scene__text__4", {opacity: 1}, 44)
                            //  .to(".studio__hero__push-to-start",  { opacity: 1 },3.5)
                            // .to(".studio__hero__push-to-start",  { opacity: 0 },4);
                        }

                        var tl_forward= gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__projects__headline",
                                start: "-100%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_forward
                            .from(".studio__projects__underline",  { width: 0  },0);


                        var tl_slogan = gsap.timeline({
                            scrollTrigger: {
                                trigger: ".studio__slogan",
                                start: "-120%",
                                end: "top",
                                scrub: true,
                                //pin: true,
                                //    pinSpacing: false,
                                //  markers: true,
                                id: "scrub"
                            }

                        });

                        tl_slogan
                            .from(".studio__slogan__stroke",  { height: 0  },0)
                            .from(".underline__inner",  { width: 0  },0.2);





                        function getSliderSettings_Studio() {
                            return {
                                slidesToShow: 1,
                                centerMode: true,
                                centerPadding: "33%",
                                dots: true,
                                autoplay: true,
                                arrows: true,
                                prevArrow: $('.arrow-prev'),
                                nextArrow: $('.arrow-next'),
                                responsive: [
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            arrows: true,
                                            dots: true,
                                            centerPadding: "5%",
                                        }
                                    }
                                ]
                            }

                        }

                        $('.slider-studio').slick(getSliderSettings_Studio());


                    }
                    else {

                    }

                };

                tiktok();

                function ready() {



                    if ( $("body").hasClass("single-projects")) {
                        var macy = Macy({
                            container: '.project__masonry',
                            trueOrder: true,
                            waitForImages: true,
                            margin: 24,
                            columns: 4,
                            breakAt: {
                                1200: 4,
                                940: 4,
                                520: 4,
                                400: 1
                            }
                        });




                        const videos = document.querySelectorAll('video'); // Get all video elements

                        let numReady = 0; // Counter for number of videos that are ready
                        let numPlaying = 0; // Counter for number of videos that are playing

                        videos.forEach(video => {
                            video.addEventListener('canplay', () => {
                                window.dispatchEvent(new Event('resize'));
                                numReady++; // Increment counter when video is ready
                                checkIfAllReadyAndPlaying(); // Check if all videos are ready and playing
                            });

                            video.addEventListener('play', () => {
                                window.dispatchEvent(new Event('resize'));
                                numPlaying++; // Increment counter when video is playing
                                checkIfAllReadyAndPlaying(); // Check if all videos are ready and playing
                            });
                        });

                        function checkIfAllReadyAndPlaying() {
                            if (numReady === videos.length && numPlaying === videos.length) {
                                window.dispatchEvent(new Event('resize'));
                            }
                        }


                        $("video").each(function (index) {
                            console.log($(this).find("source").data('src'));
                            $(this).find("source").attr("src", $(this).find("source").data('src'));
                            $(this).load();
                            console.log("reszied!");

                        });
                    }




                    $('body').css("background-color", "#ffffff");

                    if (typeof window.orientation === 'undefined') {

                        console.log("reszied! active");
                        $("video").each(function (index) {
                            console.log($(this).find("source").data('src'));
                            $(this).find("source").attr("src", $(this).find("source").data('src'));
                            $(this).load();
                            console.log("reszied!");
                            window.dispatchEvent(new Event('resize'));

                        });
                    }



                    var windowheight = $(window).innerHeight();
                    $(".banner").css('height', windowheight + 'px');

                    $(window).on('resize', function(){
                        $(".banner").css('height', windowheight + 'px');
                    });


                    $('.single-post #menu-primary-menu, .single-post .logo, .single-post .desktop-menu__right__item').removeClass('inactive');
                    $('.single-project #menu-primary-menu, .single-project .logo, .single-project .desktop-menu__right__item').removeClass('inactive');


                    var c, currentScrollTop = 0,
                        navbar = $('.single-project #menu-primary-menu, .single-project .desktop-menu__right__item');

                    /*
                                        $(window).scroll(function () {
                                            var a = $(window).scrollTop();
                                            var b = navbar.height();

                                            currentScrollTop = a;

                                            if (c < currentScrollTop && a > b + b) {
                                                navbar.addClass("inactive");
                                            } else if (c > currentScrollTop && !(a <= b)) {
                                                navbar.removeClass("inactive");
                                            }
                                            c = currentScrollTop;
                                        });
                    */
                    if ($("body").hasClass("home")) {

                        $(window).resize(function(){

                            var positionY = $(window).scrollTop();
                            var indicatorsmall = 100 - ((positionY / $(window).height()) * 100);


                            var indicatorsmallbottom = 100 - ((positionY / $(window).height()) * 100);

                            if ($(window).width() > 1600) {
                                if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 53) {
                                    indicatorsmallbottom = 53
                                }
                            }

                            else if ($(window).width() > 1400) {
                                if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 57) {
                                    indicatorsmallbottom = 57
                                }
                            }

                            else if ($(window).width() > 1200) {
                                if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 56) {
                                    indicatorsmallbottom = 56
                                }
                            }

                            else {
                                if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 60) {
                                    indicatorsmallbottom = 60
                                }
                            }

                            $('.page-template-template-home .logo').css('width', indicatorsmall + 'vw');
                            $('.page-template-template-home .logo').css('top', indicatorsmallbottom + '%');
                        });


                        var lastElement = false;
                        $(".clients em").each(function() {
                            if (lastElement && lastElement.offset().top != $(this).offset().top) {
                                $(lastElement).addClass("nobullet");
                            }
                            lastElement = $(this);
                        }).last().addClass("nobullet");
                        $(window).resize(function(){
                            $(".clients em").removeClass("nobullet");
                            $(".clients em").each(function() {
                                if (lastElement && lastElement.offset().top != $(this).offset().top) {
                                    $(lastElement).addClass("nobullet");
                                }
                                lastElement = $(this);
                            }).last().addClass("nobullet");
                        });


                        $(window).scroll(function () {
                            var image_height_scale = $('.logo').height();


                            var positionY = $(window).scrollTop();


                            // var indicatorsmallmobile = 100 - ((positionY / $(window).height()) * 110);
                            // var indicatorsmallbottommobile = 20 + ((positionY / $(window).height()) * 100);
                            var indicatorsmall = 100 - ((positionY / $(window).height()) * 110);
                            var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 100);

                            /*
                                                    var indicatorsmallbottom_48 = 48 - ((positionY / $(window).height()) * 48);
                                                    var indicatorsmallbottom_50 = 50 - ((positionY / $(window).height()) * 50);
                                                    var indicatorsmallbottom_53 = 53 - ((positionY / $(window).height()) * 53);
                                                    var indicatorsmallbottom_57 = 57 - ((positionY / $(window).height()) * 57);
                                                    var indicatorsmallbottom_56 = 56 - ((positionY / $(window).height()) * 56);
                                                    var indicatorsmallbottom_60 = 60 - ((positionY / $(window).height()) * 60);

                                                    var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 100);


                                                    if ($(window).width() > 1600 && $(window).height() > 860)  {
                                                        if (indicatorsmallbottom_53 <= 100 && indicatorsmallbottom_53 >= 53) {
                                                            indicatorsmallbottom_53 = 53
                                                        }
                                                    }

                                                    else if ($(window).width() > 1600 && $(window).height() < 860 )  {
                                                        if (indicatorsmallbottom_48 <= 100 && indicatorsmallbottom_48 >= 48) {
                                                            indicatorsmallbottom_48 = 48
                                                        }
                                                    }

                                                    else if ($(window).width() > 1400)  {
                                                        if (indicatorsmallbottom_57 <= 100 && indicatorsmallbottom_57 >= 57) {
                                                            indicatorsmallbottom_57 = 57
                                                        }
                                                    }

                                                    else if ($(window).width() > 1200) {
                                                        if (indicatorsmallbottom_56 <= 100 && indicatorsmallbottom_56 >= 56) {
                                                            indicatorsmallbottom_56 = 56
                                                        }
                                                    }

                                                    else {
                                                        if (indicatorsmallbottom_60 <= 100 && indicatorsmallbottom_60 >= 60) {
                                                            indicatorsmallbottom_60 = 60
                                                        }
                                                    }

                                                    if (indicatorsmallbottom_60 <= 0) {
                                                        indicatorsmallbottom_60 = 0
                                                    }

                                                    if (indicatorsmallbottom_57 <= 0) {
                                                        indicatorsmallbottom_57 = 0
                                                    }

                                                    if (indicatorsmallbottom_56 <= 0) {
                                                        indicatorsmallbottom_56 = 0
                                                    }

                                                    if (indicatorsmallbottom_53 <= 0) {
                                                        indicatorsmallbottom_53 = 0
                                                    }

                                                    if (indicatorsmallbottom_48 <= 0) {
                                                        indicatorsmallbottom_48 = 0
                                                    }

                                                    if (indicatorsmallbottom_60 <= 0) {
                                                        indicatorsmallbottom_60 = 0
                                                    }

                                                    */


                            if (indicatorsmallbottom <= 0) {
                                indicatorsmallbottom = 0
                            }

                            if (indicatorsmallbottom >= 100) {
                                indicatorsmallbottom = 100
                            }
                            /*
                                                  var scaler = -(image_height_scale)-image_height_scale/2;

                                               if (scaler >= 20) {
                                                      $('.page-template-template-home .logo').css('margin-top', 20 + 'px');
                                                  }
                                                  else {
                                                      $('.page-template-template-home .logo').css('margin-top', scaler + 'px');
                                                  }
                                                  */

                            // $('.page-template-template-home .mobile-logo').css('bottom','calc(' + indicatorsmallbottommobile + '% - 60px)');
                            $('.page-template-template-home .logo').css('bottom','calc(' + indicatorsmallbottom + '% - 60px)');
                            //  $('.page-template-template-home .logo').css('top', indicatorsmallbottom + '%');
                            $('.page-template-template-home .logo').css('width', indicatorsmall + 'vw');
                            //   $('.page-template-template-home .mobile-logo').css('width', indicatorsmallmobile + 'vw');


                        });
                    };



                    $(".logo").removeAttr( 'style' );

                    $(".main, .ba-slider").mousemove(function(event) {
                        var eye = $(".eye-left, .eye-right");
                        var x = (eye.offset().left) + (eye.width() / 2);
                        var y = (eye.offset().top) + (eye.height() / 2);
                        var rad = Math.atan2(event.pageX - x, event.pageY - y);
                        var rot = (rad * (180 / Math.PI) * -1) + 20;
                        eye.css({
                            '-webkit-transform': 'rotate(' + rot + 'deg)',
                            '-moz-transform': 'rotate(' + rot + 'deg)',
                            '-ms-transform': 'rotate(' + rot + 'deg)',
                            'transform': 'rotate(' + rot + 'deg)'
                        });
                    });










                    $('.featured-grid-fullwidth__item, .featured-grid__item').hover(

                        function() {
                            var bg = $(this).data('bg');
                            $('body').css("background-color", bg);
                            $('.featured-grid-fullwidth__item').not(this).addClass('inactive');
                            $('.featured-grid__item').not(this).addClass('inactive');

                        }, function() {
                            $('body').css("background-color", "#ffffff");
                            $('.featured-grid-fullwidth__item').removeClass('inactive');
                            $('.featured-grid__item').removeClass('inactive');
                        }
                    );

                  /*  $('.single__gallery').slickLightbox({
                        itemSelector: '.single__gallery__lightbox',
                        caption: 'caption',
                        captionPosition: 'bottom',
                        closeOnBackdropClick: false,
                        slick: {
                            itemSelector: '.single__gallery__lightbox',
                            navigateByKeyboard: true,
                            dots: true,
                            dotsClass: 'custom_paging',
                            customPaging: function (slider, i) {
                                //FYI just have a look at the object to find available information
                                //press f12 to access the console in most browsers
                                //you could also debug or look in the source
                                console.log(slider);
                                return  (i + 1) + '/' + slider.slideCount;
                            },
                            infinite: true,
                            arrows: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        infinite: true,
                                        dots: false,
                                        arrows: true
                                    }
                                }
                            ]
                        }
                    });*/



                    $(".left-bar").addClass("hide-menu");




                    $(document).scroll(function(e){
                        var scrollAmount = $(window).scrollTop();
                        var documentHeight = $(document).height();
                        var windowHeight = $(window).height();

                        var scrollPercent = (scrollAmount / (documentHeight - windowHeight)) * 100;
                        var roundScroll = Math.round(scrollPercent);

                        $(".scroll-bar").css("width", (scrollPercent + '%'));
                    });






                    var window_height = window.innerHeight;



                    $("#menu-primary-menu #menu-item-122").click(function(){
                        $("body").attr('id','work-navigation');
                        localStorage.setItem('scrollYPos', 0 );
                        //    console.log(localStorage.getItem("scrollYPos"));
                        //$(".logo").addClass("active");
                        $(".off-canvas-content").addClass("loading");
                        setTimeout(function() {
                            // $(window).scrollTop(window_height);
                            setTimeout(function() {
                                $(".logo").removeClass("transition");
                                $(window).scrollTop(window_height - 70);
                            },200);
                            if ('scrollRestoration' in history) {
                                history.scrollRestoration = 'manual';
                            }
                            $(".logo").addClass("transition");
                            //  $(window).scrollTop(window_height);
                        }, 200);
                    });



                    $("#menu-mobile-menu .menu-item-499").click(function(){
                        $("body").attr('id','work-navigation-mobile');
                        localStorage.setItem('scrollYPos', 0);
                        //$(".logo").addClass("active");
                        $(".off-canvas-content").addClass("loading");
                        setTimeout(function() {
                            // $(window).scrollTop(window_height);
                            setTimeout(function() {

                                $(".logo").removeClass("transition");
                                $(window).scrollTop(window_height - (window_height/8));
                            },200);

                            $(".logo").addClass("transition");
                            //  $(window).scrollTop(window_height);
                        }, 200);
                    });




                    setTimeout(function() {
                        $(".mobile-menu").removeClass("show-mobile-menu");
                    }, 400);

                    $(".mobile-trigger").removeClass("active");
                    $("body").removeClass("no-scroll");



                    if(typeof(Storage) !== 'undefined') {
                        // Set scroll position
                        var lastYPos = +localStorage.getItem('scrollYPos');
                        localStorage.setItem('scrollYPos', window.scrollY);
                    }


                    if ($("body").hasClass("single-project")) {
                        $(".off-canvas-content").addClass("loading");
                        setTimeout(function() {
                            $(window).scrollTop(0);
                            console.log('is-this-triggered?')
                            setTimeout(function() {
                                $(".off-canvas-content").removeClass("loading");
                            },200);
                            if ('scrollRestoration' in history) {
                                history.scrollRestoration = 'manual';
                            }
                        }, 200);

                        /*  $(window).scroll(function()  {
                              if($(window).scrollTop() + $(window).height() == $(document).height()) {
                              }
                          });*/
                    }
                    else if ($("body").hasClass("home")) {
                        $(".off-canvas-content").addClass("loading");
                        $(".logo").removeClass("active");
                        if(typeof(Storage) !== 'undefined') {
                            console.log("home");
                            setTimeout(function() {
                                window.scrollTo(0, lastYPos);
                                setTimeout(function() {
                                    $(".off-canvas-content").removeClass("loading");
                                },200);
                                if ('scrollRestoration' in history) {
                                    history.scrollRestoration = 'manual';
                                }
                            }, 200);
                            // Apply set scroll position from the scrollToProject function
                        }
                    }

                    else {
                        $(".off-canvas-content").addClass("loading");
                        $(".logo").addClass("loading");
                        $(".logo").removeClass("active");
                        if(typeof(Storage) !== 'undefined') {
                            setTimeout(function() {
                                $(window).scrollTop(0);
                                console.log('is-this-triggered?')
                                setTimeout(function() {
                                    $(".off-canvas-content").removeClass("loading");
                                },200);
                                if ('scrollRestoration' in history) {
                                    history.scrollRestoration = 'manual';
                                }
                            }, 200);
                            // Apply set scroll position from the scrollToProject function
                        }


                    }








                    // $('.slider-default').slick(getSliderSettings());
















                };

                function ready_new() {




                    if ($("body").hasClass("single-project")) {
                        $(".off-canvas-content").addClass("loading");
                        setTimeout(function() {
                            $(window).scrollTop(0);
                            setTimeout(function() {
                                $(".off-canvas-content").removeClass("loading");
                            },0);
                            if ('scrollRestoration' in history) {
                                history.scrollRestoration = 'manual';
                            }
                        }, 0);

                        /*  $(window).scroll(function()  {
                              if($(window).scrollTop() + $(window).height() == $(document).height()) {
                              }
                          });*/
                    }
                    else if ($("body").hasClass("home")) {
                        //  $(".off-canvas-content").addClass("loading");
                        if(typeof(Storage) !== 'undefined') {
                            console.log("home");
                            /*   setTimeout(function() {
                                   setTimeout(function() {
                                       $(".off-canvas-content").removeClass("loading");
                                   },0);
                                   if ('scrollRestoration' in history) {
                                       history.scrollRestoration = 'manual';
                                   }
                               }, 0);*/
                            // Apply set scroll position from the scrollToProject function
                        }
                    }

                    else {
                        $(".off-canvas-content").addClass("loading");
                        if(typeof(Storage) !== 'undefined') {
                            setTimeout(function() {
                                $(window).scrollTop(0);
                                setTimeout(function() {
                                    $(".off-canvas-content").removeClass("loading");
                                },0);
                                if ('scrollRestoration' in history) {
                                    history.scrollRestoration = 'manual';
                                }
                            }, 0);
                            // Apply set scroll position from the scrollToProject function
                        }


                    }








                    // $('.slider-default').slick(getSliderSettings());
















                };

                function completed() {

                    $(".video-wrapper").each(function (index) {
                        $(this).css('height', $(this).find("video").innerHeight());
                    });

                    $(window).resize(function(){
                        $(".video-wrapper").each(function (index) {
                            $(this).css('height', $(this).find("video").innerHeight());
                        });
                    });






                    if ( $("body").hasClass("page-template-template-mentor") ) {



                        var executed = false;
                        $(window).scroll(function () {

                            if ($(".stats")[0]){
                                var top_of_element = $(".stats").offset().top;
                                var bottom_of_element = $(".stats").offset().top + $(".stats").outerHeight();

                            } else {
                            }

                            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                            var top_of_screen = $(window).scrollTop();

                            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                                if (!executed) {
                                    executed = true;
                                    $('.circle').circleProgress({
                                        startAngle: -1.55,
                                        lineCap: 'round',
                                        thickness: 7,
                                        size: 200,
                                        fill: {
                                            color: '#000000',
                                        }
                                    });
                                    $('.counter').each(function () {
                                        var $this = $(this),
                                            countTo = $this.attr('data-count');
                                        $({countNum: $this.text()}).animate({
                                                countNum: countTo
                                            },
                                            {
                                                duration: 1200,
                                                easing: 'linear',
                                                step: function () {
                                                    $this.text(Math.floor(this.countNum));
                                                },
                                                complete: function () {
                                                    $this.text(this.countNum);
                                                }
                                            });
                                    });
                                }

                            } else {
                            }
                        });
                    }





                    var executed = false;
                    $(window).scroll(function () {
                        if ($("body").hasClass("single-project") ) {

                            if ($(".stats")[0]){
                                var top_of_element = $(".stats").offset().top;
                                var bottom_of_element = $(".stats").offset().top + $(".stats").outerHeight();

                            } else {
                            }


                            var top_of_element_gallery = $(".gallery-wrapper").offset().top;
                            var bottom_of_element_gallery = $(".gallery-wrapper").offset().top + $(".gallery-wrapper").outerHeight();

                            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                            var top_of_screen = $(window).scrollTop();




                            if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                                $('.single__title, .single__category').addClass('active-bottom');
                            }
                            else {
                                $('.single__title, .single__category').removeClass('active-bottom');
                            }

                            if ((bottom_of_screen > (top_of_element_gallery + $(window).innerHeight()/2)) && (top_of_screen < (bottom_of_element_gallery - $(window).innerHeight()/2))) {
                                $('.single__title, .single__category').addClass('active');
                            }
                            else {
                                $('.single__title, .single__category').removeClass('active');
                            }

                            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                                if (!executed) {
                                    executed = true;
                                    $('.circle').circleProgress({
                                        startAngle: -1.55,
                                        lineCap: 'round',
                                        thickness: 7,
                                        size: 150,
                                        fill: {
                                            color: '#000000',
                                        }
                                    });
                                    $('.counter').each(function () {
                                        var $this = $(this),
                                            countTo = $this.attr('data-count');
                                        $({countNum: $this.text()}).animate({
                                                countNum: countTo
                                            },
                                            {
                                                duration: 1200,
                                                easing: 'linear',
                                                step: function () {
                                                    $this.text(Math.floor(this.countNum));
                                                },
                                                complete: function () {
                                                    $this.text(this.countNum);
                                                }
                                            });
                                    });
                                }

                            } else {
                            }
                        }
                    });



                    function getSliderSettings() {
                        return {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            fade: true,
                            lazyLoad: 'progressive',
                            // prevArrow: $('.custom-left-arrow'),
                            // nextArrow: $('.custom-right-arrow'),
                            dots: true,
                            adaptiveHeight: true,
                            appendDots: '.custom-counter',
                            customPaging : function(slider, i) {
                                var thumb = $(slider.$slides[i]).data();
                                return '<span class="normal">'+(i+1)+'</span>';
                            }
                        }
                    }
                    $('.slider-default').slick(getSliderSettings());

                    var numSlides = $('.slick-dots li').length;
                    $( ".slick-dots li:last-child" ).after( '<span class="total">'+ numSlides +'</span>' );


                    $(".slider-default").not('.slick-initialized').slick(getSliderSettings());
                    var numSlides = $('.slick-dots li').length;
                    $( ".slick-dots li:last-child" ).after( '<span class="total">'+ numSlides +'</span>' );
                    $(".slider-default").removeClass("fadeout");


                    $('.ba-slider').each(function(){
                        var cur = $(this);
                        // Adjust the slider
                        var width = cur.width()+'px';
                        cur.find('.resize span').css('width', width);
                        // Bind dragging events
                        drags(cur.find('.handle'), cur.find('.resize'), cur);
                    });

// Update sliders on resize.
                    $(window).resize(function(){
                        $('.ba-slider').each(function(){
                            var cur = $(this);
                            var width = cur.width()+'px';
                            cur.find('.resize span').css('width', width);
                        });
                    });


                    function drags(dragElement, resizeElement, container) {


                        // Initialize the dragging event on mousedown.
                        dragElement.on('mousedown touchstart', function(e) {



                            dragElement.addClass('draggable');
                            resizeElement.addClass('resizable');

                            // Check if it's a mouse or touch event and pass along the correct value
                            var startX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

                            // Get the initial position
                            var dragWidth = dragElement.outerWidth(),
                                posX = dragElement.offset().left + dragWidth - startX,
                                containerOffset = container.offset().left,
                                containerWidth = container.outerWidth();

                            // Set limits
                            minLeft = containerOffset + 10;
                            maxLeft = containerOffset + containerWidth - dragWidth - 10;

                            // Choose an interval that keeps the movement smooth
                            var interval = 24;


                            // Calculate the dragging distance on mousemove.
                            dragElement.parents().on("mousemove touchmove", function(e) {
                                //  dragElement.parents().on('mousemove', $.throttle(interval, function(e) {

                                e.originalEvent.stopPropagation();

                                // Check if it's a mouse or touch event and pass along the correct value
                                var moveX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

                                leftValue = moveX + posX - dragWidth;

                                // Prevent going off limits
                                if ( leftValue < minLeft) {
                                    leftValue = minLeft;
                                } else if (leftValue > maxLeft) {
                                    leftValue = maxLeft;
                                }


                                var resizer = $( '.resize' ).width();

                                if (resizer > ($( window ).width()/3 * 2)) {

                                    $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                    $('.center-column .main-text, .center-column .subtitle').addClass('active');
                                    $('.right-column .main-text, .right-column .subtitle').addClass('active');



                                }

                                else if (resizer > ($( window ).width()/3 )) {

                                    $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                    $('.center-column .main-text, .center-column .subtitle').addClass('active');
                                    $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                                }

                                else if (resizer < ($( window ).width()/3 )) {

                                    $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                    $('.center-column .main-text, .center-column .subtitle').removeClass('active');
                                    $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                                }

                                else if (resizer < 150) {

                                    $('.left-column .main-text, .left-column .subtitle').removeClass('active');
                                    $('.center-column .main-text, .center-column .subtitle').removeClass('active');
                                    $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                                }

                                else {
                                    $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                }


                                // Translate the handle's left value to masked divs width.
                                widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';

                                // Set the new values for the slider and the handle.
                                // Bind mouseup events to stop dragging.
                                $('.draggable').css('left', widthValue).on('mouseup touchend touchcancel', function () {
                                    $(this).removeClass('draggable');
                                    resizeElement.removeClass('resizable');
                                    //   $(this).dragging.disable();
                                });
                                $('.resizable').css('width', widthValue);
                            }).on('mouseup touchend touchcancel', function(){
                                dragElement.removeClass('draggable');
                                resizeElement.removeClass('resizable');
                            });
                            e.preventDefault();
                        }).on('mouseup touchend touchcancel', function(e){
                            dragElement.removeClass('draggable');
                            resizeElement.removeClass('resizable');

                        });
                    }


                    AOS.init({
                        duration: 1200,
                    });

                    $(document).foundation();



                    $("#menu-item-8 a, #menu-item-323 a").addClass("no-barba");


                };

                ready_new();


                barba.use(barbaCss);
                barba.init({

                    transitions: [

                        {

                            beforeLeave: function (data) {

                            },

                            beforeEnter: function (data) {

                                ready();
                                tiktok();





                            },
                            afterEnter: function (data) {
                                completed();

                                if ( $("body").hasClass("single-casestudy") || $("body").hasClass("page-template-template-tiktok_studio") ) {

                                }
                                else {
                                    $('video').trigger('play');
                                }

                                $(".open-application").click(function(){
                                    $(".job_studio__overlay").removeClass("inactive");
                                });

                                $(".report_studio__overlay__headline svg").click(function(){
                                    $(".job_studio__overlay").addClass("inactive");
                                });



                                var $btns = $('.btn').click(function() {
                                    if (this.id == 'all') {
                                        $('.featured-news-grid').fadeIn(450);
                                        $('.normal-news-grid > div').fadeIn(450);
                                    } else {
                                        var $el = $('.normal-news-grid .' + this.id).fadeIn(450);
                                        $('.normal-news-grid > div').not($el).hide();
                                        $('.featured-news-grid').hide();
                                    }
                                    $('.btn').removeClass('active');
                                    $(this).addClass('active');
                                });



                                var window_height = window.innerHeight;
                                if ( $("body").hasClass("home-scroll") ) {
                                    $(window).scrollTop(window_height - 70);
                                    console.log("scrolled");
                                    $("body").removeClass("home-scroll");

                                    $('.home .featured-grid a:first-child [data-aos], .home .featured-grid a:nth-child(4) [data-aos], .home .featured-grid a:nth-child(7) [data-aos], .home .featured-grid a:nth-child(10) [data-aos], .home .featured-grid a:nth-child(13) [data-aos], .home .featured-grid a:nth-child(16), .home .featured-grid a:nth-child(19) [data-aos], .home .featured-grid a:nth-child(22) [data-aos], .home .featured-grid a:nth-child(25) [data-aos], .home .featured-grid a:nth-child(28) [data-aos], .home .featured-grid a:nth-child(31) [data-aos], .home .featured-grid a:nth-child(34) [data-aos], .home .featured-grid a:nth-child(37) [data-aos], .home .featured-grid a:nth-child(40)[data-aos], .home .featured-grid a:nth-child(43) [data-aos], .home .featured-grid a:nth-child(46) [data-aos], .home .featured-grid a:nth-child(49) [data-aos]').each(function() {
                                        if($(this).is(":visible")){
                                            $(this).attr('data-aos-delay', 100);
                                            $(this).addClass("number-one");
                                            console.log("300");
                                        }
                                    });


                                    $('.home .featured-grid a:nth-child(2) [data-aos], .home .featured-grid a:nth-child(5) [data-aos], .home .featured-grid a:nth-child(8) [data-aos], .home .featured-grid a:nth-child(11) [data-aos], .home .featured-grid a:nth-child(14) [data-aos], .home .featured-grid a:nth-child(17) [data-aos], .home .featured-grid a:nth-child(20) [data-aos], .home .featured-grid a:nth-child(23) [data-aos], .home .featured-grid a:nth-child(26) [data-aos], .home .featured-grid a:nth-child(29) [data-aos], .home .featured-grid a:nth-child(32) [data-aos], .home .featured-grid a:nth-child(35) [data-aos], .home .featured-grid a:nth-child(38) [data-aos], .home .featured-grid a:nth-child(41) [data-aos], .home .featured-grid a:nth-child(44) [data-aos], .home .featured-grid a:nth-child(47) [data-aos], .home .featured-grid a:nth-child(50) [data-aos]').each(function() {
                                        if($(this).is(":visible")){
                                            $(this).attr('data-aos-delay', 200);
                                            $(this).addClass("number-one");
                                            console.log("200");
                                        }
                                    });

                                    $('.home .featured-grid a:nth-child(3) [data-aos], .home .featured-grid a:nth-child(6) [data-aos], .home .featured-grid a:nth-child(9) [data-aos], .home .featured-grid a:nth-child(12) [data-aos], .home .featured-grid a:nth-child(15) [data-aos], .home .featured-grid a:nth-child(18) [data-aos], .home .featured-grid a:nth-child(21) [data-aos], .home .featured-grid a:nth-child(24) [data-aos],  .home .featured-grid a:nth-child(27) [data-aos],  .home .featured-grid a:nth-child(30) [data-aos],  .home .featured-grid a:nth-child(33) [data-aos],  .home .featured-grid a:nth-child(36) [data-aos],  .home .featured-grid a:nth-child(39) [data-aos],  .home .featured-grid a:nth-child(42) [data-aos],  .home .featured-grid a:nth-child(45) [data-aos],  .home .featured-grid a:nth-child(48) [data-aos],  .home .featured-grid a:nth-child(51) [data-aos]').each(function() {
                                        if($(this).is(":visible")){
                                            $(this).attr('data-aos-delay', 300);
                                            $(this).addClass("number-one");
                                        }
                                    });

                                }

                                if ( $("body").hasClass("home-scroll-mobile") ) {
                                    $(window).scrollTop(window_height - (window_height/8));
                                    console.log("scrolled");
                                    $("body").removeClass("home-scroll-mobile");

                                }



                                if ($('.marquee3k').length){
                                    Marquee3k.refreshAll();
                                }

                            },


                            leave: function (data) {
                            },
                            enter: function (data) {
                            },
                        },
                    ],
                });





                barba.hooks.beforeLeave((data) => {
                    $('.home video, .single-project video').addClass('hide-video');

                    $('.marquee3k, footer').css('opacity', '0');

                    $(".home video, .single-project video").each(function (index) {


                        $(this).find("source").attr("src", '');
                        $(this).remove();

                    });

                    if ($("body").hasClass("home")) {



                        $(".off-canvas-content").addClass("loading");
                    }

                });





                barba.hooks.afterLeave((data) => {
                    // Set <body> classes for "next" page
                    var nextHtml = data.next.html;
                    var response = nextHtml.replace(/(<\/?)body( .+?)?>/gi, '$1notbody$2>', nextHtml);
                    var bodyClasses = $(response).filter('notbody').attr('class');
                    $("body").attr("class", bodyClasses);

                });

                barba.hooks.afterEnter((data) => {
                    $('.marquee3k, footer').css('opacity', '1');
                });


                barba.hooks.enter(() => {
                    if ($("body").hasClass('home') && $("body").is("#work-navigation") ) {
                        $("body").addClass('home-scroll');
                        $('.featured-grid-fullwidth').removeClass("active");
                        $('.featured-grid').addClass("active");
                        $('.block-view').removeClass("active");
                        $('.grid-view').addClass("active");

                        setTimeout(function() {
                            $("body").removeClass('home-scroll');
                            $("body").attr('id', '');
                            $(".logo").addClass("transition");
                            $(".off-canvas-content").removeClass("loading");
                        },500);
                    }
                    else if ($("body").hasClass('home') && $("body").is("#work-navigation-mobile") ) {
                        $("body").addClass('home-scroll-mobile');

                        setTimeout(function() {
                            $("body").removeClass('home-scroll-mobile');
                            $("body").attr('id', '');
                            $(".off-canvas-content").removeClass("loading");
                        },1000);
                    }
                    else {
                        window.scrollTo(0, 0);
                    }

                });



                $(".video-wrapper").each(function (index) {
                    $(this).css('height', $(this).find("video").innerHeight());
                });

                $(window).resize(function(){
                    $(".video-wrapper").each(function (index) {
                        $(this).css('height', $(this).find("video").innerHeight());
                    });
                });





                var window_height = window.innerHeight;

                $(".home #menu-primary-menu #menu-item-122").click(function(){
                    setTimeout(function() {
                        $('.featured-grid-fullwidth').removeClass("active");
                        $('.featured-grid').addClass("active");
                        $('.block-view').removeClass("active");
                        $('.grid-view').addClass("active");
                    },201);

                    setTimeout(function() {
                        $("body").attr('id', '');
                        $(".off-canvas-content").removeClass("loading");
                        $("body").removeClass('home-scroll');
                        $(".logo").addClass("transition");
                    },500);
                });

                $("#menu-primary-menu #menu-item-122").click(function(){
                    $("body").attr('id','work-navigation');
                    localStorage.setItem('scrollYPos', 0 );
                    $(".off-canvas-content").addClass("loading");
                    setTimeout(function() {
                        // $(window).scrollTop(window_height);
                        setTimeout(function() {
                            $(".logo").removeClass("transition");
                            $(window).scrollTop(window_height - 70);
                        },200);
                        if ('scrollRestoration' in history) {
                            history.scrollRestoration = 'manual';
                        }
                        $(".logo").addClass("transition");
                        //  $(window).scrollTop(window_height);
                    }, 200);
                });

                $("#menu-mobile-menu .menu-item-499").click(function(){
                    $("body").attr('id','work-navigation-mobile');
                    localStorage.setItem('scrollYPos', 0);
                    //$(".logo").addClass("active");
                    $(".off-canvas-content").addClass("loading");
                    setTimeout(function() {
                        // $(window).scrollTop(window_height);
                        setTimeout(function() {
                            $(".off-canvas-content").removeClass("loading");
                            $(".logo").removeClass("transition");
                            $(window).scrollTop(window_height - (window_height/8));
                        },200);

                        $(".logo").addClass("transition");
                        //  $(window).scrollTop(window_height);
                    }, 200);
                });




                var windowheight = $(window).innerHeight();
                $(".banner").css('height', windowheight + 'px');

                $(window).on('resize', function(){
                    $(".banner").css('height', windowheight + 'px');
                });


               /* $('.single__gallery').slickLightbox({
                    itemSelector: '.single__gallery__lightbox',
                    caption: 'caption',
                    captionPosition: 'bottom',
                    closeOnBackdropClick: false,
                    slick: {
                        itemSelector: '.single__gallery__lightbox',
                        navigateByKeyboard: true,
                        dots: true,
                        dotsClass: 'custom_paging',
                        customPaging: function (slider, i) {
                            //FYI just have a look at the object to find available information
                            //press f12 to access the console in most browsers
                            //you could also debug or look in the source
                            console.log(slider);
                            return  (i + 1) + '/' + slider.slideCount;
                        },
                        infinite: true,
                        arrows: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        responsive: [
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: true,
                                    dots: false,
                                    arrows: true
                                }
                            }
                        ]
                    }
                });*/


                function getSliderSettings() {
                    return {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        fade: true,
                        lazyLoad: 'progressive',
                        // prevArrow: $('.custom-left-arrow'),
                        // nextArrow: $('.custom-right-arrow'),
                        dots: true,
                        adaptiveHeight: true,
                        appendDots: '.custom-counter',
                        customPaging : function(slider, i) {
                            var thumb = $(slider.$slides[i]).data();
                            return '<span class="normal">'+(i+1)+'</span>';
                        }
                    }
                }
                $('.slider-default').slick(getSliderSettings());

                var numSlides = $('.slick-dots li').length;
                $( ".slick-dots li:last-child" ).after( '<span class="total">'+ numSlides +'</span>' );


                $("body, .ba-slider").mousemove(function(event) {
                    var eye = $(".eye-left, .eye-right");
                    var x = (eye.offset().left) + (eye.width() / 2);
                    var y = (eye.offset().top) + (eye.height() / 2);
                    var rad = Math.atan2(event.pageX - x, event.pageY - y);
                    var rot = (rad * (180 / Math.PI) * -1) + 20;
                    eye.css({
                        '-webkit-transform': 'rotate(' + rot + 'deg)',
                        '-moz-transform': 'rotate(' + rot + 'deg)',
                        '-ms-transform': 'rotate(' + rot + 'deg)',
                        'transform': 'rotate(' + rot + 'deg)'
                    });
                });

                $('.logo').hover(function(){
                    $(this).addClass("active-hover");
                    $(".eye-left, .eye-right").addClass("transition-hover");
                }, function () {
                    $(".lid-right-top").addClass("active");

                    setTimeout(
                        function()
                        {
                            $(".eye-left, .eye-right").removeClass("transition-hover");
                            $(".logo").removeClass("active-hover");
                            $(".lid-right-top").removeClass("active");

                        }, 500);


                });








                var lastElement = false;
                $(".clients em").each(function() {
                    if (lastElement && lastElement.offset().top != $(this).offset().top) {
                        $(lastElement).addClass("nobullet");
                    }
                    lastElement = $(this);
                }).last().addClass("nobullet");
                $(window).resize(function(){
                    $(".clients em").removeClass("nobullet");
                    $(".clients em").each(function() {
                        if (lastElement && lastElement.offset().top != $(this).offset().top) {
                            $(lastElement).addClass("nobullet");
                        }
                        lastElement = $(this);
                    }).last().addClass("nobullet");
                });


                $('.ba-slider').each(function(){
                    var cur = $(this);
                    // Adjust the slider
                    var width = cur.width()+'px';
                    cur.find('.resize span').css('width', width);
                    // Bind dragging events
                    drags(cur.find('.handle'), cur.find('.resize'), cur);
                });

// Update sliders on resize.
                $(window).resize(function(){
                    $('.ba-slider').each(function(){
                        var cur = $(this);
                        var width = cur.width()+'px';
                        cur.find('.resize span').css('width', width);
                    });
                });

                function drags(dragElement, resizeElement, container) {


                    // Initialize the dragging event on mousedown.
                    dragElement.on('mousedown touchstart', function(e) {



                        dragElement.addClass('draggable');
                        resizeElement.addClass('resizable');

                        // Check if it's a mouse or touch event and pass along the correct value
                        var startX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

                        // Get the initial position
                        var dragWidth = dragElement.outerWidth(),
                            posX = dragElement.offset().left + dragWidth - startX,
                            containerOffset = container.offset().left,
                            containerWidth = container.outerWidth();

                        // Set limits
                        minLeft = containerOffset + 10;
                        maxLeft = containerOffset + containerWidth - dragWidth - 10;

                        // Choose an interval that keeps the movement smooth
                        var interval = 24;


                        // Calculate the dragging distance on mousemove.
                        dragElement.parents().on("mousemove touchmove", function(e) {
                            //  dragElement.parents().on('mousemove', $.throttle(interval, function(e) {

                            e.originalEvent.stopPropagation();

                            // Check if it's a mouse or touch event and pass along the correct value
                            var moveX = (e.pageX) ? e.pageX : e.originalEvent.touches[0].pageX;

                            leftValue = moveX + posX - dragWidth;

                            // Prevent going off limits
                            if ( leftValue < minLeft) {
                                leftValue = minLeft;
                            } else if (leftValue > maxLeft) {
                                leftValue = maxLeft;
                            }


                            var resizer = $( '.resize' ).width();

                            if (resizer > ($( window ).width()/3 * 2)) {

                                $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                $('.center-column .main-text, .center-column .subtitle').addClass('active');
                                $('.right-column .main-text, .right-column .subtitle').addClass('active');



                            }

                            else if (resizer > ($( window ).width()/3 )) {

                                $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                $('.center-column .main-text, .center-column .subtitle').addClass('active');
                                $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                            }

                            else if (resizer < ($( window ).width()/3 )) {

                                $('.left-column .main-text, .left-column .subtitle').addClass('active');
                                $('.center-column .main-text, .center-column .subtitle').removeClass('active');
                                $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                            }

                            else if (resizer < 150) {

                                $('.left-column .main-text, .left-column .subtitle').removeClass('active');
                                $('.center-column .main-text, .center-column .subtitle').removeClass('active');
                                $('.right-column .main-text, .right-column .subtitle').removeClass('active');


                            }

                            else {
                                $('.left-column .main-text, .left-column .subtitle').addClass('active');
                            }


                            // Translate the handle's left value to masked divs width.
                            widthValue = (leftValue + dragWidth/2 - containerOffset)*100/containerWidth+'%';

                            // Set the new values for the slider and the handle.
                            // Bind mouseup events to stop dragging.
                            $('.draggable').css('left', widthValue).on('mouseup touchend touchcancel', function () {
                                $(this).removeClass('draggable');
                                resizeElement.removeClass('resizable');
                                $(this).dragging.disable();
                            });
                            $('.resizable').css('width', widthValue);
                        }).on('mouseup touchend touchcancel', function(){
                            dragElement.removeClass('draggable');
                            resizeElement.removeClass('resizable');
                        });
                        e.preventDefault();
                    }).on('mouseup touchend touchcancel', function(e){
                        dragElement.removeClass('draggable');
                        resizeElement.removeClass('resizable');

                    });
                }








                if ($(window).scrollTop() > 0 && window.location.href === "https://www.okcool.io/") {
                    $('.home .main-header').addClass('solid');
                    $('.banner').addClass('banner__solid');
                    $('.banner__text').addClass('active');
                    $('.banner__logo').addClass('active');
                    $('.featured-grid-fullwidth').addClass('insight');

                    $('.page-template-template-home .menu-hometemplate').addClass('active');
                    setTimeout(
                        function()
                        {
                            $('.logo').addClass('transition');
                        }, 400);
                    $('.logo').removeClass('active');
                    $('.mobile-logo').removeClass('active');
                    $('#svg-logo-fill, .desktop-menu a').addClass('items__solid');



                }



                else {
                    $('.main-header').removeClass('solid');
                    $('.banner').removeClass('banner__solid');
                    $('.logo').addClass('active');
                    $('.featured-grid-fullwidth').removeClass('insight');
                    $('.page-template-template-home .menu-hometemplate').removeClass('active');
                    $('.mobile-logo').addClass('active');
                    $('.banner__text').removeClass('active');
                    $('.banner__logo').removeClass('active');
                    $('.logo').addClass('transition');
                    $('#svg-logo-fill, .desktop-menu a').removeClass('items__solid');

                }







                // change body class on scroll
                $(window).scroll(function() {

                    $('.logo').addClass('pointer');

                    setTimeout(
                        function()
                        {
                            $('.logo').removeClass('pointer');
                        }, 1000);




                    if ($(this).scrollTop() >= $( window ).height() - ($( window ).height()/3) ) {
                        $('.single__herotext').addClass('active');
                        $('.new__hero').addClass('active');
                        $('.banner__text').addClass('active-mobile');
                    }

                    else {
                        $('.new__hero').removeClass('active');
                        $('.single__herotext').removeClass('active');
                        $('.banner__text').removeClass('active-mobile');

                    }
                });






                // change body class on scroll
                $(window).scroll(function() {
                    if ($(this).scrollTop() > 0) {
                        $('.home .main-header').addClass('solid');
                        $('.banner').addClass('banner__solid');
                        $('.banner__text').addClass('active');
                        $('.banner__logo').addClass('active');

                        $('.single-post #menu-primary-menu, .single-post .logo, .single-post .desktop-menu__right__item').addClass('inactive');


                        $('.single-project #menu-primary-menu, .single-project .logo, .single-project .desktop-menu__right__item').addClass('inactive');

                        $('.logo').removeClass('active');
                        $('.featured-grid-fullwidth').addClass('insight');
                        $('.page-template-template-home .menu-hometemplate').addClass('active');
                        $('.mobile-logo').removeClass('active');
                        $('.mobile-logo').addClass('active-middle');
                        $('#svg-logo-fill, .desktop-menu a').addClass('items__solid');
                        $('.news-single__header__fixed').addClass('active');
                        if ($(window).width() > 768) {
                            $('.single__herotext__spacer').addClass('active');
                        }

                        $('.news-single__content').addClass('active');

                    }


                    else {
                        /*  $(".mobile-trigger, .close-wrapper").click(function(){
                            $(".mobile-logo").toggleClass("active");
                          });*/
                        $('.page-template-template-home .menu-hometemplate').removeClass('active');
                        $('.news-single__header__fixed').removeClass('active');
                        $('.news-single__content').removeClass('active');
                        $('.main-header').removeClass('solid');
                        $('.banner').removeClass('banner__solid');
                        $('.single-post #menu-primary-menu, .single-post .logo, .single-post .desktop-menu__right__item').removeClass('inactive');
                        $('.single-project #menu-primary-menu, .single-project .logo, .single-project .desktop-menu__right__item').removeClass('inactive');
                        $('.logo').addClass('active');
                        $('.featured-grid-fullwidth').removeClass('insight');
                        $(".logo").addClass("transition");
                        $('.mobile-logo').addClass('active');
                        $('.banner__text').removeClass('active');
                        $('.banner__logo').removeClass('active');
                        $('.single__herotext__spacer').removeClass('active');
                        $('.single__herotext').removeClass('active');
                        $('#svg-logo-fill, .desktop-menu a').removeClass('items__solid');
                    }
                });

                $('.featured-grid-fullwidth__item, .featured-grid__item').hover(

                    function() {
                        var bg = $(this).data('bg');
                        $('body').css("background-color", bg);
                        $('.featured-grid-fullwidth__item').not(this).addClass('inactive');
                        $('.featured-grid__item').not(this).addClass('inactive');

                    }, function() {
                        $('body').css("background-color", "#ffffff");
                        $('.featured-grid-fullwidth__item').removeClass('inactive');
                        $('.featured-grid__item').removeClass('inactive');
                    }
                );





                /*  function update() {
                      var date = moment(new Date());
                      date = date.tz('Europe/London').format('h:mm:ss a');
                      $("#london").empty().append(date);
                      var date2 = moment(new Date());
                      date2 = date2.tz('America/Los_Angeles').format('h:mm:ss a');
                      $("#la").empty().append(date2);
                  }

                  setInterval(update, 1000);
  */
                var progressBarOptions = {
                    startAngle: -1.55,
                    size: 200,
                    value: 0.75,
                    fill: {
                        color: '#ffa500'
                    }
                };

                /*
                                     var c, currentScrollTop = 0,
                                         navbar = $('.single-project #menu-primary-menu, .single-project .desktop-menu__right__item');


                                     $(window).scroll(function () {
                                       var a = $(window).scrollTop();
                                       var b = navbar.height();

                                       currentScrollTop = a;

                                       if (c < currentScrollTop && a > b + b) {
                                         navbar.addClass("inactive");
                                       } else if (c > currentScrollTop && !(a <= b)) {
                                         navbar.removeClass("inactive");
                                       }
                                       c = currentScrollTop;
                                     });
                */


                /*
                                $(".main-header").mouseover(function() {
                                    $('#menu-primary-menu, .desktop-menu__right__item').addClass('active-hover');
                                    $('#menu-primary-menu, .desktop-menu__right__item').removeClass('inactive');
                                });
                                $(".main-header").mouseout(function() {
                                    $('#menu-primary-menu, .desktop-menu__right__item').removeClass('active-hover')
                                });
                */



                $(".single__gallery__testimonial").hover(function(){

                    $(".single__title").addClass("active-testimonial");  //Add the active class to the area is hovered
                }, function () {
                    $(".single__title").removeClass("active-testimonial");
                });


                if ( $("body").hasClass("page-template-template-mentor") ) {


                    var executed = false;
                    $(window).scroll(function () {

                        if ($(".stats")[0]){
                            var top_of_element = $(".stats").offset().top;
                            var bottom_of_element = $(".stats").offset().top + $(".stats").outerHeight();

                        } else {
                        }

                        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                        var top_of_screen = $(window).scrollTop();

                        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                            if (!executed) {
                                executed = true;
                                $('.circle').circleProgress({
                                    startAngle: -1.55,
                                    lineCap: 'round',
                                    thickness: 7,
                                    size: 200,
                                    fill: {
                                        color: '#000000',
                                    }
                                });
                                $('.counter').each(function () {
                                    var $this = $(this),
                                        countTo = $this.attr('data-count');
                                    $({countNum: $this.text()}).animate({
                                            countNum: countTo
                                        },
                                        {
                                            duration: 1200,
                                            easing: 'linear',
                                            step: function () {
                                                $this.text(Math.floor(this.countNum));
                                            },
                                            complete: function () {
                                                $this.text(this.countNum);
                                            }
                                        });
                                });
                            }

                        } else {
                        }
                    });
                }


                if ($("body").hasClass("single-project") ) {



                    var executed = false;
                    $(window).scroll(function () {

                        if ($(".stats")[0]){
                            var top_of_element = $(".stats").offset().top;
                            var bottom_of_element = $(".stats").offset().top + $(".stats").outerHeight();

                        } else {
                        }

                        var top_of_element_gallery = $(".gallery-wrapper").offset().top;
                        var bottom_of_element_gallery = $(".gallery-wrapper").offset().top + $(".gallery-wrapper").outerHeight();

                        var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                        var top_of_screen = $(window).scrollTop();






                        if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                            $('.single__title, .single__category').addClass('active-bottom');
                        }
                        else {
                            $('.single__title, .single__category').removeClass('active-bottom');
                        }

                        if ((bottom_of_screen > (top_of_element_gallery + $(window).innerHeight()/2)) && (top_of_screen < (bottom_of_element_gallery - $(window).innerHeight()/2))) {
                            $('.single__title, .single__category').addClass('active');
                        }
                        else {
                            $('.single__title, .single__category').removeClass('active');
                        }

                        if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                            if (!executed) {
                                executed = true;
                                $('.circle').circleProgress({
                                    startAngle: -1.55,
                                    lineCap: 'round',
                                    thickness: 7,
                                    size: 150,
                                    fill: {
                                        color: '#000000',
                                    }
                                });
                                $('.counter').each(function () {
                                    var $this = $(this),
                                        countTo = $this.attr('data-count');
                                    $({countNum: $this.text()}).animate({
                                            countNum: countTo
                                        },
                                        {
                                            duration: 1200,
                                            easing: 'linear',
                                            step: function () {
                                                $this.text(Math.floor(this.countNum));
                                            },
                                            complete: function () {
                                                $this.text(this.countNum);
                                            }
                                        });
                                });
                            }

                        } else {
                        }
                    });
                }











                var executed = false;
                // change body class on scroll
                $(window).scroll(function() {
                    if ($(this).scrollTop() > 1) {
                        $('.main-bar-projects').addClass('active');
                        $(".filter-system, .banner-projects, .filter, .close").removeClass("active");
                    } else {
                        $('.main-bar-projects').removeClass('active');
                    }
                });

                $(document).scroll(function(e){
                    var scrollAmount = $(window).scrollTop();
                    var documentHeight = $(document).height();
                    var windowHeight = $(window).height();

                    var scrollPercent = (scrollAmount / (documentHeight - windowHeight)) * 100;
                    var roundScroll = Math.round(scrollPercent);

                    $(".scroll-bar").css("width", (scrollPercent + '%'));
                });

                //Use this inside your document ready jQuery


                if ($("body").hasClass("home")) {

                    $(window).resize(function(){

                        var positionY = $(window).scrollTop();
                        var indicatorsmall = 100 - ((positionY / $(window).height()) * 100);


                        var indicatorsmallbottom = 100 - ((positionY / $(window).height()) * 100);


                        if ($(window).width() > 1600) {
                            if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 53) {
                                indicatorsmallbottom = 53
                            }
                        }

                        if ($(window).width() > 1600 && $(window).height() <= 860 )  {
                            indicatorsmallbottom = 48
                        }

                        else if ($(window).width() > 1400) {
                            if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 57) {
                                indicatorsmallbottom = 57
                            }
                        }

                        else if ($(window).width() > 1200) {
                            if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 56) {
                                indicatorsmallbottom = 56
                            }
                        }

                        else {
                            if (indicatorsmallbottom <= 100 && indicatorsmallbottom >= 60) {
                                indicatorsmallbottom = 60
                            }
                        }

                        $('.page-template-template-home .logo').css('width', indicatorsmall + 'vw');
                        $('.page-template-template-home .logo').css('top', indicatorsmallbottom + '%');
                    });

                    var image_height = $('.logo').height();
                    $('.page-template-template-home .logo').css('margin-top', -(image_height)-image_height/2 + 'px');





                    $(window).scroll(function () {
                        var image_height_scale = $('.logo').height();


                        var positionY = $(window).scrollTop();


                        // var indicatorsmallmobile = 100 - ((positionY / $(window).height()) * 110);
                        // var indicatorsmallbottommobile = 20 + ((positionY / $(window).height()) * 100);
                        if ($(window).width() < 1400)   {
                            var indicatorsmall = 100 - ((positionY / $(window).height()) * 105);
                        }
                        else {
                            var indicatorsmall = 100 - ((positionY / $(window).height()) * 110);
                        }
                        var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 100);

                        /*
                                                var indicatorsmallbottom_48 = 48 - ((positionY / $(window).height()) * 48);
                                                var indicatorsmallbottom_50 = 50 - ((positionY / $(window).height()) * 50);
                                                var indicatorsmallbottom_53 = 53 - ((positionY / $(window).height()) * 53);
                                                var indicatorsmallbottom_57 = 57 - ((positionY / $(window).height()) * 57);
                                                var indicatorsmallbottom_56 = 56 - ((positionY / $(window).height()) * 56);
                                                var indicatorsmallbottom_60 = 60 - ((positionY / $(window).height()) * 60);

                                                var indicatorsmallbottom = 20 + ((positionY / $(window).height()) * 100);


                                                if ($(window).width() > 1600 && $(window).height() > 860)  {
                                                    if (indicatorsmallbottom_53 <= 100 && indicatorsmallbottom_53 >= 53) {
                                                        indicatorsmallbottom_53 = 53
                                                    }
                                                }

                                                else if ($(window).width() > 1600 && $(window).height() < 860 )  {
                                                    if (indicatorsmallbottom_48 <= 100 && indicatorsmallbottom_48 >= 48) {
                                                        indicatorsmallbottom_48 = 48
                                                    }
                                                }

                                                else if ($(window).width() > 1400)  {
                                                    if (indicatorsmallbottom_57 <= 100 && indicatorsmallbottom_57 >= 57) {
                                                        indicatorsmallbottom_57 = 57
                                                    }
                                                }

                                                else if ($(window).width() > 1200) {
                                                    if (indicatorsmallbottom_56 <= 100 && indicatorsmallbottom_56 >= 56) {
                                                        indicatorsmallbottom_56 = 56
                                                    }
                                                }

                                                else {
                                                    if (indicatorsmallbottom_60 <= 100 && indicatorsmallbottom_60 >= 60) {
                                                        indicatorsmallbottom_60 = 60
                                                    }
                                                }

                                                if (indicatorsmallbottom_60 <= 0) {
                                                    indicatorsmallbottom_60 = 0
                                                }

                                                if (indicatorsmallbottom_57 <= 0) {
                                                    indicatorsmallbottom_57 = 0
                                                }

                                                if (indicatorsmallbottom_56 <= 0) {
                                                    indicatorsmallbottom_56 = 0
                                                }

                                                if (indicatorsmallbottom_53 <= 0) {
                                                    indicatorsmallbottom_53 = 0
                                                }

                                                if (indicatorsmallbottom_48 <= 0) {
                                                    indicatorsmallbottom_48 = 0
                                                }

                                                if (indicatorsmallbottom_60 <= 0) {
                                                    indicatorsmallbottom_60 = 0
                                                }

                                                */


                        if (indicatorsmallbottom <= 0) {
                            indicatorsmallbottom = 0
                        }


                        if (indicatorsmallbottom >= 100) {
                            indicatorsmallbottom = 100
                        }
                        /*
                                              var scaler = -(image_height_scale)-image_height_scale/2;

                                           if (scaler >= 20) {
                                                  $('.page-template-template-home .logo').css('margin-top', 20 + 'px');
                                              }
                                              else {
                                                  $('.page-template-template-home .logo').css('margin-top', scaler + 'px');
                                              }
                                              */

                        // $('.page-template-template-home .mobile-logo').css('bottom','calc(' + indicatorsmallbottommobile + '% - 60px)');
                        $('.page-template-template-home .logo').css('bottom','calc(' + indicatorsmallbottom + '% - 60px)');
                        //  $('.page-template-template-home .logo').css('top', indicatorsmallbottom + '%');
                        $('.page-template-template-home .logo').css('width', indicatorsmall + 'vw');
                        //   $('.page-template-template-home .mobile-logo').css('width', indicatorsmallmobile + 'vw');


                    });
                }
                else {
                    $(window).on('popstate', function() {
                      //  location.reload(true);
                    });
                }


//almost down - footer fix
                $(window).scroll(function() {
                    if($(window).scrollTop() + $(window).height() > $(document).height() - 20) {
                        $('.single-meta-description__quote, .single-meta-description__socials, .single-description__inner').addClass("active");
                    }
                    else {
                        $('.single-meta-description__quote, .single-meta-description__socials, .single-description__inner').removeClass("active");
                    }
                });



                // add no-touch class for desktop browsers
                var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
                if (!touchsupport) {
                    document.documentElement.className += " no-touch ";
                }






                if (typeof window.orientation === 'undefined') {



                    $("video").each(function (index) {
                        //   console.log($(this).find("source").data('src'));
                        //   $(this).find("source").attr("src", $(this).find("source").data('src'));
                        //    $(this).load();
                        //    $(this)[0].play();

                    });
                }
                else {
                    $(".home video, .single-project video").each(function (index) {
                        console.log($(this).find("source").data('src'));
                        $(this).find("source").attr("src", "");
                        $(this)[0].pause();
                        $(this).remove();

                    });
                }










































                var window_height = window.innerHeight;


                $('.grid-view').click(function() {
                    setTimeout(function () {
                        $("[data-aos]").removeClass('aos-animate');
                        AOS.refresh();
                    }, 50);
                    $(".video-wrapper").each(function (index) {
                        $(this).css('height', $(this).find("video").innerHeight());
                    });
                    $("[data-aos]").removeClass('aos-animate');
                    $(this).addClass('active');
                    $('.featured-grid').addClass("active");

                    $('.block-view').removeClass("active");
                    $('.featured-grid-fullwidth').removeClass("active");


                    $(window).scrollTop(window_height - 70);


                });

                $('.block-view').click(function() {
                    setTimeout(function () {
                        $("[data-aos]").removeClass('aos-animate');
                        AOS.refresh();
                    }, 50);

                    // $("[data-aos]").addClass('aos-animate-is');
                    $(".video-wrapper").each(function (index) {
                        $(this).css('height', $(this).find("video").innerHeight());
                    });
                    $(this).addClass('active');
                    $('.featured-grid-fullwidth').addClass("active");

                    $('.grid-view').removeClass("active");
                    $('.featured-grid').removeClass("active");

                    $(window).scrollTop(window_height);
                });


                /*  var sec = 0;
                  function pad ( val ) { return val > 9 ? val : "0" + val; }
                  setInterval( function(){
                      $(".seconds").html(pad(++sec%60));
                      $(".minutes").html(pad(parseInt(sec/60,10)));
                      $(".hours").html(pad(parseInt(sec/3600,10)));
                  }, 1000);
  */







                $(".close-bg").click(function(){
                    $(".gallery-image").removeClass('opaque triggered-first triggered-second triggered-third');
                    $('.gallery-text').removeClass('active');
                    $(this).hide();
                });

                $(".close-about-text").click(function(){
                    $(".gallery-image").removeClass('opaque triggered-first triggered-second triggered-third');
                    $('.gallery-text').removeClass('active');
                    $('.close-bg').hide();
                });




                if ($("body").hasClass("page-template-template-carrers-new")) {
                    setTimeout(function() {
                        if ($(window).width() > 768) {
                            $('html, body').animate({
                                scrollTop: $(location.hash).offset().top - 54
                            }, 1000);
                        }
                        else {
                            $('html, body').animate({
                                scrollTop: $(location.hash).offset().top - 105
                            }, 1000);
                        }
                    },500);
                }



























                localStorage.setItem('project_view', 'block'); // Change the line where you set the localStorate item checkcalfid

                var project_view = localStorage.getItem('project_view');

                if (project_view == 'block') { // Check if it's equal to the string true
                }
                else if (project_view == 'grid') {
                }
                else {

                }






















                AOS.init({
                    duration: 1200,
                });


                $('.home .featured-grid a:first-child [data-aos], .home .featured-grid a:nth-child(4) [data-aos], .home .featured-grid a:nth-child(7) [data-aos], .home .featured-grid a:nth-child(10) [data-aos], .home .featured-grid a:nth-child(13) [data-aos], .home .featured-grid a:nth-child(16), .home .featured-grid a:nth-child(19) [data-aos], .home .featured-grid a:nth-child(22) [data-aos], .home .featured-grid a:nth-child(25) [data-aos], .home .featured-grid a:nth-child(28) [data-aos], .home .featured-grid a:nth-child(31) [data-aos], .home .featured-grid a:nth-child(34) [data-aos], .home .featured-grid a:nth-child(37) [data-aos], .home .featured-grid a:nth-child(40)[data-aos], .home .featured-grid a:nth-child(43) [data-aos], .home .featured-grid a:nth-child(46) [data-aos], .home .featured-grid a:nth-child(49) [data-aos]').each(function() {
                    if($(this).is(":visible")){
                        $(this).attr('data-aos-delay', 100);
                        $(this).addClass("number-one");
                        console.log("300");
                    }
                });


                $('.home .featured-grid a:nth-child(2) [data-aos], .home .featured-grid a:nth-child(5) [data-aos], .home .featured-grid a:nth-child(8) [data-aos], .home .featured-grid a:nth-child(11) [data-aos], .home .featured-grid a:nth-child(14) [data-aos], .home .featured-grid a:nth-child(17) [data-aos], .home .featured-grid a:nth-child(20) [data-aos], .home .featured-grid a:nth-child(23) [data-aos], .home .featured-grid a:nth-child(26) [data-aos], .home .featured-grid a:nth-child(29) [data-aos], .home .featured-grid a:nth-child(32) [data-aos], .home .featured-grid a:nth-child(35) [data-aos], .home .featured-grid a:nth-child(38) [data-aos], .home .featured-grid a:nth-child(41) [data-aos], .home .featured-grid a:nth-child(44) [data-aos], .home .featured-grid a:nth-child(47) [data-aos], .home .featured-grid a:nth-child(50) [data-aos]').each(function() {
                    if($(this).is(":visible")){
                        $(this).attr('data-aos-delay', 200);
                        $(this).addClass("number-one");
                        console.log("200");
                    }
                });

                $('.home .featured-grid a:nth-child(3) [data-aos], .home .featured-grid a:nth-child(6) [data-aos], .home .featured-grid a:nth-child(9) [data-aos], .home .featured-grid a:nth-child(12) [data-aos], .home .featured-grid a:nth-child(15) [data-aos], .home .featured-grid a:nth-child(18) [data-aos], .home .featured-grid a:nth-child(21) [data-aos], .home .featured-grid a:nth-child(24) [data-aos],  .home .featured-grid a:nth-child(27) [data-aos],  .home .featured-grid a:nth-child(30) [data-aos],  .home .featured-grid a:nth-child(33) [data-aos],  .home .featured-grid a:nth-child(36) [data-aos],  .home .featured-grid a:nth-child(39) [data-aos],  .home .featured-grid a:nth-child(42) [data-aos],  .home .featured-grid a:nth-child(45) [data-aos],  .home .featured-grid a:nth-child(48) [data-aos],  .home .featured-grid a:nth-child(51) [data-aos]').each(function() {
                    if($(this).is(":visible")){
                        $(this).attr('data-aos-delay', 300);
                        $(this).addClass("number-one");
                    }
                });


                if ($('.marquee3k').length){
                    setTimeout(function () {
                        Marquee3k.init();
                    }, 1000);
                }



                /**
                 * MENU TRIGGER
                 */
                $(".menu-trigger, .left-bar .close-button").click(function(){
                    $(".off-canvas-wrapper, .logo-horizontal, .logo-vertical, .cart-trigger").toggleClass("blur");
                    $(".left-bar").toggleClass("hide-menu");
                    $(".by-digi").toggleClass("show-by-digi");

                });




                $(".mobile-trigger, .close-wrapper").click(function(){
                    $(".mobile-logo").removeClass("active");

                    $(".mobile-trigger").addClass("active");
                    $(".menu-trigger, .cart-new").toggleClass("black-menu-trigger");
                    $(".mobile-menu").addClass("show-mobile-menu");
                    $("body").addClass("no-scroll");
                });


                $(".mobile-open-trigger").click(function(){
                    if ($(window).scrollTop() <= 2 && window.location.href === "https://www.okcool.io/") {
                        $(".mobile-logo").addClass("active");
                    }

                    $(".mobile-menu").removeClass("show-mobile-menu");
                    $(".mobile-trigger").removeClass("active");
                    $("body").removeClass("no-scroll");

                });











                /**
                 * HORIZONTAL SCROLL
                 */



































                /*filter*/

                var $cards = $('.section, li.product');
                var $btns = $('.tag').on('click', function() {

                    if ($(this).hasClass("active")) {
                        $cards
                            .fadeIn(450);
                        $(this).removeClass("active");



                    }

                    else {
                        var active =
                            $btns.removeClass('active')
                                .filter(this)
                                .addClass('active')
                                .data('filter');

                        $cards
                            .hide()
                            .filter('.' + active)
                            .fadeIn(450);


                    }



                });


                $('.slider-about').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // asNavFor: '.slider-for',
                    // dots: true,
                    centerMode: true,
                    focusOnSelect: true,
                    prevArrow: $('.prev'),
                    nextArrow: $('.next')
                });



                $('.slider-normal').slick({

                    infinite: true,
                    speed: 1000,
                    centerMode: true,
                    variableWidth: true,
                    autoplay: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: false,
                    customPaging : function(slider, i) {
                        var thumb = $(slider.$slides[i]).data();
                        return '<span class="normal">'+(i+1)+'</span>';
                    },
                    responsive: [
                        {

                            breakpoint: 992,
                            settings: {
                                infinite: true,
                                speed: 1000,
                                centerMode: true,
                                variableWidth: true,
                                arrows: true,
                                autoplay: true
                            }
                        }
                    ]
                });








                $(".trigger-mobile-menu").click(function(){
                    $(".mobile-menu").addClass("show-mobile-menu");
                    $(".mobile-logo").removeClass("active");
                    $(".body-overlay").show("fast");
                    $("body").css({
                        'right':'125px',
                        'transition' : 'all 0.2s'
                    });
                });

                $(".close-mobile-menu, .body-overlay").click(function(){
                    $(".mobile-menu").removeClass("show-mobile-menu");
                    $(".body-overlay").hide("fast");
                    $("body").css({
                        'right':'0',
                        'transition' : 'all 0.2s'
                    });
                });













// Arrow Down Scroll
                $(document).on('click', '.arrow-down', function(event){
                    event.preventDefault();
                    var viewportHeight = $(window).height();

                    $('html, body').animate({
                        scrollTop: viewportHeight + 10,
                        complete: function () {
                        }
                    }, 700);
                });



                $('.scroll-wrapper').click(function(){
                    $("html").animate({ scrollTop: $(window).height()}, 300);
                    return false;
                });


                // fire initial resize
                //$(window).trigger('resize');

            },
            finalize: function() {
                // $(window).trigger('resize');
                // JavaScript to be fired on all pages, after page specific JS is fired


            },
            resize: function() {






















                document.addEventListener('loadResponsivePostsComplete', function(e) {
                    //console.log("loadResponsivePostsComplete");
                }, false);

                // Sticky footer
                function stickyFooter() {
                    if (Foundation.MediaQuery.atLeast('large')) {
                        $("main.main").css('min-height', combinedHeight + "px");
                    } else {
                        $("main.main").css('min-height', "100vh");
                    }
                }

                if ($( "#footer-wrap" ).length) {
                    stickyFooter();
                }

            },
            windowload: function() {




                $('#loading-screen').hide();


                //console.log('loaded');
                $('html').addClass('window-loaded');
            }
        },
        // Home page
        'home': {
            init: function() {

                jQuery(function($) {


                });
                // JavaScript to be fired on the home page


            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS

            },
            resize: function() {





            },
            windowload: function() {

            }
        },
        'page_template_template_layouts': {
            init: function() {

            },
            finalize: function() {

            },
            resize: function() {

            },
            windowload: function() {

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
                //UTIL.fire(classnm, 'resize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
            //UTIL.fire('common','resize');
        },
        resizeEvents: function() {

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'resize');
            });

            UTIL.fire('common','resize');

        },
        windowLoadEvents: function() {

            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm, 'windowload');
            });

            UTIL.fire('common','windowload');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(window).on("load",UTIL.windowLoadEvents);

    // Resize Events
    var trackWindowWidth = $(window).width();

    $(window).resize(function (trackResizeEvent) {
        //console.log(!trackResizeEvent.isTrigger ? ' resize' : ' triggerd');

        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll || the resize event was caused by $(window).trigger('resize');
        if (($(window).width() !== trackWindowWidth) || trackResizeEvent.isTrigger) {
            // Update the window width for next time
            trackWindowWidth = $(window).width();

            UTIL.resizeEvents();
        }
    });


})(jQuery); // Fully reference jQuery after this point.



